import React, { useState, useContext } from 'react';
import '../styles/VestrPlus.css';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../helper/PaymentHelper';
import axios from 'axios';
import config from '../config';
import vestrPlus1 from '../assets/vestrPlus1.png';
import vestrPlus1Bg from '../assets/vestrPlus1Bg.png';
import vestrPlus2 from '../assets/vestrPlus2.png';
import vestrPlus2Bg from '../assets/vestrPlus2Bg.png';
import vestrPlus3 from '../assets/vestrPlus3.png';
import vestrPlus3Bg from '../assets/vestrPlus3Bg.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../context/AuthContext';


const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function VestrPlus() {
  const [activeTab, setActiveTab] = useState('Monthly');
  const {isAuthenticated} = useContext(AuthContext);  

  const handleCheckout = async (productTier) => {
    if (!isAuthenticated) {
      alert('Please sign in to continue');
      return;
    }
    const stripe = await stripePromise;

    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/create-checkout-session`, {
        productTier, //Green or gold
        billingCycle: activeTab, // Monthly or Annual
      });

      const session = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className="vp-container">
      <div className="FeedSubHeader" style={{ alignSelf: 'flex-start', height: '3rem'}}>
        <div className="FeedNavItems">
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'Monthly' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => setActiveTab('Monthly')}
          >
            Monthly (50% off)
          </button>
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'Annual' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => setActiveTab('Annual')}
          >
            Annual (58% off)
          </button>
        </div>
      </div>
      <hr className="hr-header" />
      <div className="vp-content">
        <div className="vp-card">
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus1Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus1} alt="Vestr Plus 1" />
          </div>
          <h2 className="vp-type-title bold">Silver</h2>
          <h3 className="vp-cost bold">Free</h3>
          <button className="vp-button bold">Current</button>
          <div className="vp-perks">
            <ul>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Silver founder badge on profile</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> No check mark next to name</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 Mr. Vestr prompts/mo</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 5 years of financial data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 2 years of KPI data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 Events (calls, transcripts, and slides)</li>
            </ul>
          </div>
        </div>
        <div className="vp-card">
          <div className="vp-off bold">
            { activeTab === 'Monthly' ?  <span className ="bold">50% off</span> : <span className="bold">17% off</span>}
          </div>
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus2Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus2} alt="Vestr Plus 2" />
          </div>
          <h2 className="vp-type-title bold">Green</h2>
          <h3 className="vp-cost bold">
            <span className="bold">${activeTab === 'Annual' ? '88' : '4.44'} </span> 
            <span className="vp-per">/{activeTab === 'Annual' ? 'year' : 'month'}</span>
            {activeTab === 'Annual' ? (
              <span className="vp-discount-2 bold">$106.56</span> 
            ) : 
              <span className="vp-discount bold">$8.88</span>
            }
          </h3>
          <button 
            className="vp-button bold" 
            style={{ border: 'none', color: '#fff', backgroundImage: 'linear-gradient(to right, #1DCF5B, #1BFF6A)' }}
            onClick={() => handleCheckout('GREEN')}
          >
            Start Now
          </button>
          <div className="vp-perks">
            <ul>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Green founder badge on profile</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Green check mark next to name </li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr (Vestr AI)</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Unlimited Mr. Vestr prompts</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 years of financial data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 5 years of KPI data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 Events (calls, transcripts, and slides)</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 years of estimates & Ratings</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> First priority support</li>
            </ul>
          </div>
        </div>
        <div className="vp-card">
          { activeTab === "Annual" && <div className = "vp-off bold">
            17% off
          </div>}
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus3Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus3} alt="Vestr Plus 3" />
          </div>
          <h2 className="vp-type-title bold">Gold</h2>
          <h3 className="vp-cost bold">
            <span className="bold">${activeTab === 'Annual' ? '170' : '17'} </span> 
            <span className="vp-per medium"> /{activeTab === 'Annual' ? 'year' : 'month'}</span>
            {activeTab === 'Annual' && (
              <span className="vp-discount-2 bold">$204</span> 
            )}
          </h3>
          <button
            className="vp-button bold"
            style={{ border: 'none', color: '#fff', backgroundImage: 'linear-gradient(to right, #FFBF19, #FFDF8B)' }}
            onClick={() => handleCheckout('GOLD')}
          >
            Start Now
          </button>
          <div className="vp-perks">
            <ul>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Gold founder badge on profile</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Gold name and check mark next to name</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr (Vestr AI)</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Unlimited Mr. Vestr prompts</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 20 years of financial data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 12 years of KPI data</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 30+ Events (calls, transcripts, and slides)</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 years of estimates & Ratings</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> First priority support</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Invite to monthly call with Vestr Team to discuss roadmap and strategy</li>
              <li><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Personal phone call from Vestr CEO</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}