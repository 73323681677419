import React, { useState, useEffect, useContext } from 'react';
import Cropper from 'react-easy-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../styles/Profile.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { AuthContext } from '../context/AuthContext';
import Post from './Post';
import { formatTimeSince, timeSince } from '../helper/PostHelper';
import { filterPosts } from '../helper/ProfileHelper';
import github from '../assets/github.svg';
import instagram from '../assets/instagram.svg';
import tiktok from '../assets/tiktok.svg';
import twitter from '../assets/twitter.svg';
import youtube from '../assets/youtube.svg';
import discord from '../assets/discord.svg';
import substack from '../assets/substack.svg';
import website from '../assets/website.svg';
import pencil from '../assets/pencil.svg';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';

/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 */

export default function Profile() {

  const [activeTab, setActiveTab] = useState('posts');

  /** @type {[PostObject[], Function]} */
  const [userPosts, setUserPosts] = useState([]);

  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mutualFollowers, setMutualFollowers] = useState([]);
  const navigate = useNavigate();
  const { username } = useParams();
  const { userId: currentUserId, userToken, setUsername, setName, setProfilePicture, signOut } = useContext(AuthContext);

  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null);

  /** @type {[UserObject, Function]} */
  const [viewingUser, setViewingUser] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [editUsername, setEditUsername] = useState('');
  const [editName, setEditName] = useState('');
  const [editBio, setEditBio] = useState('');
  const [selectedTime, setSelectedTime] = useState('All time');
  const [selectedSort, setSelectedSort] = useState('Most recent');
  const [editSocial, setEditSocial] = useState({
    instagram: '',
    youtube: '',
    discord: '',
    tiktok: '',
    substack: '',
    github: '',
    website: '',
  });
  const [editProfilePic, setEditProfilePic] = useState('');
  const [editBannerPic, setEditBannerPic] = useState('');

  // Cropper state for profile picture
  const [showPfpCropper, setShowPfpCropper] = useState(false);
  const [pfpCrop, setPfpCrop] = useState({ x: 0, y: 0 });
  const [pfpZoom, setPfpZoom] = useState(1);
  const [pfpCroppedAreaPixels, setPfpCroppedAreaPixels] = useState(null);
  const [pfpImageSrc, setPfpImageSrc] = useState(null);

  // Cropper state for banner picture
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [bannerCrop, setBannerCrop] = useState({ x: 0, y: 0 });
  const [bannerZoom, setBannerZoom] = useState(1);
  const [bannerCroppedAreaPixels, setBannerCroppedAreaPixels] = useState(null);
  const [bannerImageSrc, setBannerImageSrc] = useState(null);

  // Fixed aspect ratio for banner image
  const bannerAspectRatio = 16 / 4; // Adjust this ratio to match your desired aspect ratio

  const handlePfpChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB

      if (file.size > maxSize) {
        alert('File size exceeds 3 MB. Please upload a smaller file.');
        return;
      }
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPfpImageSrc(reader.result);
          setShowPfpCropper(true);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file (JPEG, PNG, GIF, WEBP, or SVG).');
      }
    } else {
      console.error('No file selected or invalid file');
    }
  };

  const handleBannerPicChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB

      if (file.size > maxSize) {
        alert('File size exceeds 3 MB. Please upload a smaller file.');
        return;
      }
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImageSrc(reader.result);
          setShowBannerCropper(true);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file (JPEG, PNG, GIF, WEBP, or SVG).');
      }
    } else {
      console.error('No file selected or invalid file');
    }
  };

  const handlePfpCropComplete = async () => {
    try {
      const croppedImageBase64 = await getCroppedImg(pfpImageSrc, pfpCroppedAreaPixels);
      setEditProfilePic(croppedImageBase64);
      setShowPfpCropper(false);
    } catch (error) {
      console.error('Error cropping image', error);
    }
  };

  const handleBannerCropComplete = async () => {
    try {
      const croppedImageBase64 = await getCroppedImg(bannerImageSrc, bannerCroppedAreaPixels);
      setEditBannerPic(croppedImageBase64);
      setShowBannerCropper(false);
    } catch (error) {
      console.error('Error cropping image', error);
    }
  };

  function getCroppedImg(imageSrc, croppedAreaPixels) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        const base64Image = canvas.toDataURL('image/jpeg', 0.8);
        resolve(base64Image);
      };
      image.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handleSocialChange = (key, value) => {
    setEditSocial({ ...editSocial, [key]: value });
  };

  const handleSave = async () => {
    try {
      if (!editUsername) {
        alert('Username is required');
        return;
      }
      if (!editName) {
        alert('Name is required');
        return;
      }
      if (editBio.length > 80) {
        alert('Bio must be less than 80 characters');
        return;
      }

      const formData = {
        userId: currentUserId,
        username: editUsername,
        name: editName,
        bio: editBio,
        social: JSON.stringify(editSocial),
        profilePicture: editProfilePic,  // Base64 string
        bannerPicture: editBannerPic,    // Base64 string
      };

      await axios.put(`${config.backendUrl}/api/auth/edit-profile`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setEditMode(false);
      navigate(`/profile/${editUsername}`);
      alert('Profile updated successfully');
    } catch (error) {
      alert(error.response?.data?.message || 'Error saving profile');
      console.error('Error saving profile:', error);
    }
  };

  const handleFollowClick = async () => {
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/auth/follow`,
        { currentUserId, viewingUserId: userId },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      setIsFollowing(response.data.isFollowing);

      if (response.data.isFollowing) {
        setCurrentUser((prev) => ({
          ...prev,
          following: [...prev.following, userId],
        }));
        setViewingUser((prev) => ({
          ...prev,
          followers: [...prev.followers, currentUserId],
        }));
      } else {
        setCurrentUser((prev) => ({
          ...prev,
          following: prev.following.filter((id) => id !== userId),
        }));
        setViewingUser((prev) => ({
          ...prev,
          followers: prev.followers.filter((id) => id !== currentUserId),
        }));
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const handleBookmarkClick = async (postId, isBookmarked) => {
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`;
      const method = isBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setEditUsername(viewingUser?.username || '');
    setEditName(viewingUser?.name || '');
    setEditBio(viewingUser?.bio || '');
    setEditSocial(viewingUser?.social || {});
    setEditProfilePic(viewingUser?.profilePicture || '');
    setEditBannerPic(viewingUser?.bannerPicture || '');
  };

  useEffect(() => {
    if (editMode) return;
    const fetchUserData = async () => {
      if (!currentUserId || !username) return;

      try {
        const postResponse = await axios.get(`${config.backendUrl}/api/posts/user/${username}/posts`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        const { posts, userIdPoster } = postResponse.data;

        setUserPosts(posts);
        console.log('posts', posts);
        setUserId(userIdPoster);

        if (currentUserId !== userIdPoster) {
          const [userResponse, currentUserResponse] = await Promise.all([
            axios.get(`${config.backendUrl}/api/auth/${userIdPoster}`),
            axios.get(`${config.backendUrl}/api/auth/${currentUserId}`),
          ]);

          setViewingUser(userResponse.data);
          setCurrentUser(currentUserResponse.data);
          setIsFollowing(currentUserResponse.data.following.includes(userIdPoster));

          const mutualFollowersResponse = await axios.post(
            `${config.backendUrl}/api/auth/mutual-followers`,
            {
              currentUserId,
              viewingUserId: userIdPoster,
            },
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          );
          setMutualFollowers(mutualFollowersResponse.data.mutualFollowers);
        } else {
          const currentUserResponse = await axios.get(`${config.backendUrl}/api/auth/${currentUserId}`);
          setCurrentUser(currentUserResponse.data);
          setViewingUser(currentUserResponse.data);
          setUsername(currentUserResponse.data.username);
          setName(currentUserResponse.data.name);
          setProfilePicture(currentUserResponse.data.profilePicture);
          localStorage.setItem('username', currentUserResponse.data.username);
          localStorage.setItem('name', currentUserResponse.data.name);
          localStorage.setItem('profilePicture', currentUserResponse.data.profilePicture);

          setIsFollowing(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setViewingUser(null);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchUserData();
  }, [username, currentUserId, userToken, editMode, setName, setProfilePicture, setUsername]);

  const renderMutualFollowers = () => {
    if (mutualFollowers.length === 0 || currentUserId === userId) return null;

    const displayedFollowers = mutualFollowers.slice(0, 2).map((follower) => follower.username).join(', ');

    if (mutualFollowers.length > 2) {
      return (
        <p className="pr-followers-info">
          Followed by <span className="bold">{displayedFollowers}</span> and{' '}
          <span className="bold">{mutualFollowers.length - 2} others</span>
        </p>
      );
    }

    return (
      <p className="pr-followers-info">
        Followed by <span className="bold">{displayedFollowers}</span>
      </p>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (activeTab === 'posts') {
      const filteredPosts = filterPosts(userPosts, selectedTime, selectedSort);

      return filteredPosts.length > 0 ? (
        filteredPosts.map((post) => {
          return (
            <React.Fragment key={post._id}>
              <Post
                key={post._id}
                postId={post._id}
                profilePic={post.author.profilePicture}
                author={post.author.name}
                verified={post.author.isUserVerified}
                username={post.author.username}
                upvotes={post.upvotes}
                downvotes={post.downvotes}
                content={post.content}
                comments={post.comments}
                reposts={post.reposts}
                isQuotePost={post.isQuotePost}
                quotePostId={post.quotePostId}
                time={formatTimeSince(timeSince(post.timePosted))}
                title={post.title}
                sentiment={post.sentiment}
                type={post.type}
                categories={post.categories}
                bookmarked={currentUser.bookmarks.includes(post._id)}
                onBookmarkClick={() => handleBookmarkClick(post._id, currentUser.bookmarks.includes(post._id))}
                images={post.images}
                isUpvoted={post.upvotes.some((upvote) => upvote.user && upvote.user.toString() === currentUserId)}
                isDownvoted={post.downvotes.some((downvote) => downvote.user && downvote.user.toString() === currentUserId)}
                caption={post.caption}
                isAdmin={currentUser?.isAdmin}
                refreshFeed={()=> setUserPosts(userPosts.filter((p) => p._id !== post._id))}
                isAuthorFollowed={currentUser.following.includes(post.author._id) || post.author._id === currentUserId}
                authorId={post.author._id}
                refreshFollowingStatus={() => setIsFollowing(!isFollowing)}
                copyReposterId={userId}
                isCopyRepost={post.copyReposts.includes(userId)}
              />
            </React.Fragment>
          );
        })
      ) : (
        <div>No posts available.</div>
      );
    }

    return null;
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="pr-Profile">
      <div className="pr-top-bar">
        <div className="pr-feed-wrapper" onClick={handleBackClick} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faArrowLeft} className="pr-back-icon" />
          <span className="pr-title bold">Back</span>
        </div>
        <span className="pr-username bold">@{username}</span>
      </div>

      {!loading ? (
        viewingUser ? (
          viewingUser.isPublic || userId === currentUserId ? (
            <>
              <div
                className="pr-banner-image-wrapper"
                style={{ cursor: editMode ? 'pointer' : '' }}
                onClick={() => editMode && document.getElementById('bannerPicInput').click()}
              >
                <img
                  src={editBannerPic || viewingUser?.bannerPicture}
                  alt="Profile Header"
                  className="pr-banner-image"
                />
              </div>
              <input
                type="file"
                id="bannerPicInput"
                style={{ display: 'none' }}
                onChange={handleBannerPicChange}
                disabled={!editMode}
              />
              <div className="pr-profile-main-content" style={{ display: 'flex' }}>
                <div className="pr-profile-sidebar">
                  <div className="pr-profile-info">
                    <div
                      style={{ cursor: editMode ? 'pointer' : '' }}
                      className="pr-profile-picture-wrapper"
                      onClick={() => editMode && document.getElementById('profilePicInput').click()}
                    >
                      <img
                        src={editProfilePic || viewingUser?.profilePicture}
                        alt="Profile"
                        className="pr-profile-picture"
                      />
                      <input
                        type="file"
                        id="profilePicInput"
                        style={{ display: 'none' }}
                        onChange={handlePfpChange}
                        disabled={!editMode}
                      />

                      {editMode && (
                        <div className="pr-pencil-container">
                          <img src={pencil} alt="Edit" className="pr-pencil-icon" />
                        </div>
                      )}
                    </div>

                    <div className="pr-main-profile-info-wrapper">
                      <h2 className="pr-name-title bold">{editName || viewingUser?.name}</h2>
                      <h3 className="pr-username-title regular" style={{ textAlign: 'left' }}>@{editUsername || username}</h3>
                      <div className="pr-actions">
                        {currentUserId !== userId ? (
                          <button className="pr-follow-btn bold" onClick={handleFollowClick}>
                            {isFollowing ? 'Unfollow' : 'Follow'}
                          </button>
                        ) : !editMode ? (
                          <>
                            <button className="pr-follow-btn bold" onClick={handleEditClick}>
                              Edit
                            </button>
                            <button className="pr-follow-btn bold" onClick={signOut}>
                              Log out
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="pr-follow-btn bold" onClick={handleSave}>
                              Save
                            </button>
                       
                            <button className="pr-follow-btn bold" onClick={signOut}>
                              Log out
                            </button>
                          </>
                        )}
                      </div>
                      {renderMutualFollowers()}
                      <p className="pr-bio">{viewingUser?.bio}</p>
                      <div className="pr-stats">
                        <p><span className="bold">{viewingUser?.followers.length}</span> followers</p>
                        <p><span className="bold">{viewingUser?.following.length}</span> following</p>
                      </div>
                      <div className="pr-social-media-icons">
                        {viewingUser?.social.instagram && (
                          <a href={viewingUser.social.instagram} target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt="Instagram" className="pr-social-icon" />
                          </a>
                        )}

                        {viewingUser?.social.twitter && (
                          <a href={viewingUser.social.twitter} target="_blank" rel="noopener noreferrer">
                            <img src={twitter} alt="X" className="pr-social-icon" />
                          </a>
                        )}

                        {viewingUser?.social.tiktok && (
                          <a href={viewingUser.social.tiktok} target="_blank" rel="noopener noreferrer">
                            <img src={tiktok} alt="TikTok" className="pr-social-icon" />
                          </a>
                        )}

                        {viewingUser?.social.discord && (
                          <a href={viewingUser.social.discord} target="_blank" rel="noopener noreferrer">
                            <img src={discord} alt="Discord" className="pr-social-icon" />
                          </a>
                        )}
                        {viewingUser?.social.youtube && (
                          <a href={viewingUser.social.youtube} target="_blank" rel="noopener noreferrer">
                            <img src={youtube} alt="YouTube" className="pr-social-icon" />
                          </a>
                        )}

                        {viewingUser?.social.website && (
                          <a href={viewingUser.social.website} target="_blank" rel="noopener noreferrer">
                            <img src={website} alt="Website" className="pr-social-icon" />
                          </a>
                        )}

                        {viewingUser?.social.github && (
                          <a href={viewingUser.social.github} target="_blank" rel="noopener noreferrer">
                            <img src={github} alt="GitHub" className="pr-social-icon" />
                          </a>
                        )}
                        {viewingUser?.social.substack && (
                          <a href={viewingUser.social.substack} target="_blank" rel="noopener noreferrer">
                            <img src={substack} alt="Substack" className="pr-social-icon" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pr-main-profile-content">
                  {!editMode ? (
                    <>
                      <div className="pr-ProfileTopBar">
                        <div className="pr-ProfileTopBarNavItems">
                          <button
                            className={`pr-ProfileTopBarNavButton bold ${
                              activeTab === 'posts' ? 'pr-ProfileTopBarNavButtonActive' : ''
                            }`}
                            onClick={() => setActiveTab('posts')}
                          >
                            Posts
                          </button>
                        </div>
                      </div>
                      <div className="pr-LowerTopBarProfile">
                        <select className="pr-LowerTopBarButtonProfile bold" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                          <option value="All time">All time</option>
                          <option value="Today">Today</option>
                          <option value="7 days">Last 7 days</option>
                          <option value="1 month">Last 1 month</option>
                        </select>
                        <select className="pr-LowerTopBarButtonProfile bold" value={selectedSort} onChange={(e) => setSelectedSort(e.target.value)}>
                          <option value="Most recent">Most recent</option>
                          <option value="Most popular">Most popular</option>
                          <option value="Most commented">Most commented</option>
                        </select>
                      </div>

                      <div className="pr-feed-content">{renderContent()}</div>
                    </>
                  ) : (
                    <div className="pr-MainEditContainer">
                      <div className="pr-EditProfileContainer">
                        <h2 className="pr-EditTitle">Edit Profile</h2>
                        <hr className="pr-hr" />

                        <div className="pr-MainEditWrapper">
                          <div className="pr-UsernameEditContainer">
                            <div className="pr-EditFieldContainer">
                              <label className="pr-FieldName bold">Username</label>
                              <input
                                type="text"
                                className="pr-EditInput"
                                value={editUsername}
                                onChange={(e) => setEditUsername(e.target.value)}
                              />
                            </div>
                            <div className="pr-GuideText">
                              Usernames can only contain letters, numbers, underscores, and periods. Changing your
                              username will also change your profile link.
                            </div>
                          </div>

                          <div className="pr-NameEditContainer">
                            <div className="pr-EditFieldContainer">
                              <label className="pr-FieldName bold">Name</label>
                              <div className="pr-InputGuideWrapper">
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="pr-GuideText">Your nickname can only be changed once every 7 days.</div>
                          </div>

                          <div className="pr-BioEditContainer">
                            <div className="pr-EditFieldContainer">
                              <label className="pr-FieldName bold">Bio</label>
                              <div className="pr-InputGuideWrapper">
                                <textarea
                                  className="pr-EditTextarea"
                                  value={editBio}
                                  onChange={(e) => setEditBio(e.target.value)}
                                />
                                <div className="pr-GuideText">{editBio.length}/80</div>
                              </div>
                            </div>
                          </div>

                          <div className="pr-WebsiteEditContainer">
                            <div className="pr-EditFieldContainer">
                              <label className="pr-FieldName bold">Website</label>
                              <input
                                type="text"
                                className="pr-EditInput"
                                value={editSocial.website}
                                onChange={(e) => handleSocialChange('website', e.target.value)}
                                placeholder="https://"
                              />
                            </div>
                          </div>

                          <div className="pr-SocialEditContainer">
                            <div className="pr-EditFieldContainer">
                              <label style={{ alignSelf: 'flex-start', marginTop: '1rem' }} className="pr-FieldName bold">
                                Social
                              </label>
                              <div className="pr-SocialEditContainer">
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="Instagram"
                                  value={editSocial.instagram}
                                  onChange={(e) => handleSocialChange('instagram', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="Twitter"
                                  value={editSocial.twitter}
                                  onChange={(e) => handleSocialChange('twitter', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="Youtube"
                                  value={editSocial.youtube}
                                  onChange={(e) => handleSocialChange('youtube', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="Discord"
                                  value={editSocial.discord}
                                  onChange={(e) => handleSocialChange('discord', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="TikTok"
                                  value={editSocial.tiktok}
                                  onChange={(e) => handleSocialChange('tiktok', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="Substack"
                                  value={editSocial.substack}
                                  onChange={(e) => handleSocialChange('substack', e.target.value)}
                                />
                                <input
                                  type="text"
                                  className="pr-EditInput"
                                  placeholder="GitHub"
                                  value={editSocial.github}
                                  onChange={(e) => handleSocialChange('github', e.target.value)}
                                />
                                <button className="pr-follow-btn bold" onClick={handleSave}>
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Profile Picture Cropper */}
              <Dialog open={showPfpCropper} onClose={() => setShowPfpCropper(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                  <div className="crop-container">
                    <Cropper
                      image={pfpImageSrc}
                      crop={pfpCrop}
                      zoom={pfpZoom}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onCropChange={setPfpCrop}
                      onZoomChange={setPfpZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) => {
                        setPfpCroppedAreaPixels(croppedAreaPixels);
                      }}
                    />
                  </div>
                  <div className="crop-actions">
                  <span>
                      Scale: {pfpZoom}x
                    </span>
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={pfpZoom}
                      onChange={(e) => setPfpZoom(e.target.value)}
                    />
                    <div className="crop-buttons">
                      <button className="pr-follow-btn bold" onClick={handlePfpCropComplete}>Save</button>
                      <button className="pr-follow-btn bold" onClick={() => setShowPfpCropper(false)}>Cancel</button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              {/* Banner Picture Cropper */}
              <Dialog open={showBannerCropper} onClose={() => setShowBannerCropper(false)} maxWidth="md" fullWidth>
                <DialogContent>
                  <div className="crop-container">
                    <Cropper
                      image={bannerImageSrc}
                      crop={bannerCrop}
                      zoom={bannerZoom}
                      aspect={bannerAspectRatio}
                      onCropChange={setBannerCrop}
                      onZoomChange={setBannerZoom}
                      showGrid={false}
                      onCropComplete={(croppedArea, croppedAreaPixels) => {
                        setBannerCroppedAreaPixels(croppedAreaPixels);
                      }}
                    />
                  </div>
                  <div className="crop-actions">
                    <span>
                      Scale: {bannerZoom}x
                    </span>
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={bannerZoom}
                      onChange={(e) => setBannerZoom(e.target.value)}
                      style = {{backgroundColor: 'green'}}
                    />
                    <div className="crop-buttons">
                      <button className="pr-follow-btn bold" onClick={handleBannerCropComplete}>Save</button>
                      <button className="pr-follow-btn bold" onClick={() => setShowBannerCropper(false)}>Cancel</button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <div>User is private</div>
          )
        ) : (
          <div>User not found</div>
        )
      ) : (
        <div className="pr-Profile loading"><CircularProgress size="3rem" /></div>
      )}
    </div>
  );
}
