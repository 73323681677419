import React, { useState, useContext, useEffect } from 'react';
import '../styles/Post.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { AuthContext } from '../context/AuthContext';
import { 
  getSentimentColor, 
  formatTimeSince, 
  timeSince, 
  calculateTotalCount,
  allowedHTML, 
} from '../helper/PostHelper';
import retweet from '../assets/retweet.svg';
import comment from '../assets/comment.svg';  
import { fetchUser } from '../helper/ProfileHelper';

import CircularProgress from '@mui/material/CircularProgress';
import Repost from './post-actions/Repost';
import Upvote from './post-actions/Upvote';
import Downvote from './post-actions/Downvote';
import Dots from './post-actions/Dots';
import Bookmark from './post-actions/Bookmark';
import DOMPurify from 'dompurify';
/**
 * 
 * @typedef {import('../helper/PostHelper').QuotePostDetailsObject} QuotePostDetailsObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 *
 * 
 * 
 */



/**
 * Post component represents an individual post within a feed.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.postId - The ID of the post.
 * @param {string} props.profilePic - The profile picture base64 of the post's author.
 * @param {string} props.author - The author of the post
 * @param {string} props.authorId - The ID of the post's author.
 * @param {boolean} props.verified - Whether the post's author is verified.
 * @param {string} props.username - The username of the post's author.
 * @param {Array} props.upvotes - Array of upvote objects.
 * @param {Array} props.downvotes - Array of downvote objects.
 * @param {string} props.content - The content of the post.
 * @param {Array} props.comments - Array of post comment IDs on the post.
 * @param {Array} props.reposts - Array of user IDs who reposted.
 * @param {boolean} props.isQuotePost - Whether the post is a quote post.
 * @param {string} props.quotePostId - The ID of the original post being quoted.
 * @param {string} props.time - The time the post was created.
 * @param {string} props.title - The title of the post.
 * @param {string} props.sentiment - The sentiment of the post.
 * @param {string} props.type - The type of the post.
 * @param {string[]} props.categories - The categories of the post.
 * @param {boolean} props.bookmarked - Whether the post is bookmarked by the current user.
 * @param {Function} props.onBookmarkClick - Function to handle bookmark clicks.
 * @param {string[]} props.images - Array of image base64 in the post, only 1 for now.
 * @param {boolean} props.isCopyRepost - Whether the post is a copy repost.
 * @param {string} props.copyReposterId - The ID of the user who copy reposted the post.
 * @param {boolean} props.isDownvoted - Whether the post is downvoted by the current user.
 * @param {boolean} props.isUpvoted - Whether the post is upvoted by the current user.
 * @param {boolean} props.isAdmin - Whether the current user is an admin.
 * @param {string} props.caption - The caption for the post's image.
 * @param {Function} props.refreshFeed - Function to refresh the parent components feed.
 * @param {Function} props.refreshFollowingStatus - Function to refresh the user following status of parent component.
 * @param {boolean} props.isComment - Whether the post is a comment. 
 * @param {string} props.parentPostId - The ID of the parent post. 
 * @param {boolean} props.isAuthorFollowed - Whether the author is followed by the current user.
 * @returns {JSX.Element} The rendered Post component.
 */

const Post = ({
  postId,
  profilePic,
  author,
  verified,
  username,
  upvotes,
  downvotes,
  content,
  comments,
  reposts,
  isQuotePost,
  quotePostId,
  time,
  title,
  sentiment,
  type,
  categories,
  bookmarked,
  onBookmarkClick,
  images,
  isCopyRepost,  
  copyReposterId,
  isDownvoted,
  isUpvoted,
  isAdmin,
  caption,
  refreshFeed, 
  refreshFollowingStatus,
  parentPostId,
  isComment,
  isAuthorFollowed, //prop from parent
  authorId,

}) => {

  const navigate = useNavigate();
  const { userToken, isAuthenticated, userId } = useContext(AuthContext);
  const [isUpvotedState, setIsUpvotedState] = useState(isUpvoted);
  const [isDownvotedState, setIsDownvotedState] = useState(isDownvoted);
  const [upvoteCount, setUpvoteCount] = useState(calculateTotalCount(upvotes));
  const [downvoteCount, setDownvoteCount] = useState(calculateTotalCount(downvotes));
  const [repostCount, setRepostCount] = useState(reposts?.length);
  const [isCopyReposted, setIsCopyReposted] = useState(reposts?.includes(userId));
  const [isAuthorFollowedState, setIsAuthorFollowedState] = useState(isAuthorFollowed);
  const [isBookmarked, setIsBookmarked] = useState(bookmarked);

  /** @type {[QuotePostDetailsObject, Function]} */
  const [quotePostDetails, setQuotePostDetails] = useState(null);

  const [quotePostLoading, setQuotePostLoading] = useState(true);
  const [copyReposterLoading, setCopyReposterLoading] = useState(true);

  /** @type {[UserObject, Function]} */
  const [copyReposter, setCopyReposter] = useState(null);



  // Sync local state with props whenever isAuthorFollowed changes
  useEffect(() => {
    setIsAuthorFollowedState(isAuthorFollowed); 
  }, [isAuthorFollowed]); 


  useEffect(() => {
    const fetchQuotePost = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${quotePostId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setQuotePostDetails(response.data);
      } catch (error) {
        console.error('Error fetching quote post:', error);
      } finally {
        setQuotePostLoading(false);
      }
    };
  
    const fetchCopyReposter = async () => {
      try {
        const response = await fetchUser(copyReposterId, userToken);
        setCopyReposter(response.data);
      } catch (error) {
        console.error('Error fetching copy reposter:', error);
      } finally {
        setCopyReposterLoading(false);
      }
    };
    if (isQuotePost) {
      fetchQuotePost();
    } else {
      setQuotePostLoading(false);
    }
  
    if (isCopyRepost) {
      fetchCopyReposter();
    } else {
      setCopyReposterLoading(false);
    }
  }, [quotePostId, copyReposterId, isQuotePost, isCopyRepost, userToken]);

  const handleProfileClick = () => {
    if (!isAuthenticated) { 
      alert('Please sign in to view profiles');
      return;
    }
    navigate(`/profile/${username}`);
  };



  const handleFollowClick = async () => {

    if (!isAuthenticated) {
      alert('Please sign in to follow users');
      return;
    }
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/auth/follow`,
        { currentUserId: userId, viewingUserId: authorId },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      setIsAuthorFollowedState(true);
      refreshFollowingStatus(authorId);
      alert(response.data.message);


    } catch (error) {
      console.error('Error following user:', error);
      alert('Error following user');
    }
  };







  const getLimitedContent = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };



  return (
<div className="PostContainerWrapper">
  {quotePostLoading || copyReposterLoading ? (
    <div className="PostContainer loading" ><CircularProgress size="3rem"/></div>
  ) : (
    <>
      {isCopyRepost && (
        <span className="ReposterText">
          {copyReposter.name} reposted <img src={retweet} alt="Repost" className="ActionIcon" />
        </span>
      )}

      <div className='PostContainer'>
        <div className="ProfilePicContainer" >
          <div style= {{position: 'relative'}}>
          <img src={profilePic} alt="Profile" className="ProfilePic" onClick={handleProfileClick} />
          {!isAuthorFollowedState && 
          <div className="PostFollowUserBtn" onClick={handleFollowClick}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
            }
          </div>
       
        </div>
        <div className="PostContentContainer">
          <div className="PostHeader">
            <div className="PostAuthor bold" onClick={handleProfileClick}>
              {author} {verified && <FontAwesomeIcon style={{ marginLeft: '0.1rem', marginRight: '0.4rem' }} icon={faCheckCircle} className="Verified" />}
            </div>
            <div className="PostDetails" onClick={handleProfileClick} style={{ marginLeft: !verified ? '0.3rem' : '0rem' }}>
              @{username} · {time}  {type && <span> · {type}</span>} {sentiment && <>· <span className="bold" style={{ color: getSentimentColor(sentiment) }}>{sentiment}</span></>}
            </div>
          </div>
          <div className="PostContent" onClick={()=> navigate(`/post/${postId}`)}>
            <h2 className="PostContentH2 bold">{title && <>{title}</>}</h2>
            <div
            className="PostContentP"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getLimitedContent(content, 150), allowedHTML) }}
            onClick={(e) => {
              if (e.target.tagName === 'A') {
                e.stopPropagation(); // Prevents outer div's onClick from firing
              }
            }}
          ></div>

              {content.split(' ').length > 150 && (
                <p className="read-more bold" style={{color: '#1DCF5B'}}>Read More</p>
              )}
    
            {images && images.map((image, index) => (
              <img key={index} src={image} alt={`Post ${index}`} className="PostImage" />
            ))}
            {caption && <p className="PostContentCaption">{caption}</p>}
            {isQuotePost && (
              <div className="cp-QuotePostContainer">
                {quotePostDetails ? (
                  <>
                    <div className="cp-QuotePostHeader">
                      <img src={`${quotePostDetails.author.profilePicture}`} alt="Profile" className="cp-QuoteProfilePic" />
                      <div className="cp-QuotePostInfoWrapper" style={{ flexDirection: 'column' }}>
                        <div className="cp-QuotePostInfo">
                          <div className="cp-QuotePostAuthor cp-bold">
                            <span className="bold" style={{ marginRight: !verified ? '0.3rem' : '0rem' }}>{quotePostDetails.author.name}</span> {quotePostDetails.isUserVerified && <FontAwesomeIcon style={{ marginLeft: '0.1rem', marginRight: '0.4rem' }} icon={faCheckCircle} className="Verified" />}
                          </div>
                          <div className="cp-QuotePostDetails">
                            @{quotePostDetails.author.username} · {formatTimeSince(timeSince(quotePostDetails.timePosted))} 
                            {quotePostDetails.sentiment && <> <span className="bold" style={{ color: getSentimentColor(quotePostDetails.sentiment) }}> 
                            <span style={{color: '#707070'}}>· </span>  
                            {quotePostDetails.sentiment}</span> </>}
                          </div>
                        </div>
                        <h2 className="cp-QuotePostTitle bold">{quotePostDetails.title && <>{quotePostDetails.title }</>}</h2>
                        <div className="cp-QuotePostContent">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(getLimitedContent(quotePostDetails.content, 150), allowedHTML ),
                          
                        }}
                        onClick={(e) => {
                          if (e.target.tagName === 'A') {
                            e.stopPropagation(); // Prevents outer div's onClick from firing
                          }
                        }}
                        
                      ></div>
                        </div>  
                            {quotePostDetails.images?.length > 0 && quotePostDetails.images.map((image, index) => (
                          <img key={index} src={image} alt={`Post ${index}`} className="PostImage-sm" />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Post was deleted or could not be loaded</p>
                )}
              </div>
            )}
          </div>

          <div className="PostActions" onClick={(e) => e.stopPropagation()}>
            <div className="LeftActions">
              <div className="ActionItem" onClick={()=> navigate(`/post/${postId}`)} style={{ gap: '0.5rem' }}>
                <object aria-label="comments" type="image/svg+xml" data={comment} alt="Comment" className="ActionIcon" /> {comments.length}
              </div>
             <Repost
                postId={postId} 
                profilePic={profilePic}
                author={author}
                verified={verified}
                username={username}
                content={content}
                title={title}
                time={time}
                sentiment={sentiment}
                type={type}
                categories={categories}
                images={images}
                repostCount={repostCount}
                setRepostCount={setRepostCount}
                isCopyReposted={isCopyReposted}
                setIsCopyReposted={setIsCopyReposted}
                userToken={userToken}
                />
              <div className="UpvoteDownvoteContainer">
                <Upvote
                  isAdmin={isAdmin}
                  isUpvotedState={isUpvotedState}
                  setIsUpvotedState={setIsUpvotedState}
                  setIsDownvotedState={setIsDownvotedState}
                  setUpvoteCount={setUpvoteCount}
                  setDownvoteCount={setDownvoteCount}
                  postId={postId}
                />
                <span>{upvoteCount - downvoteCount}</span>
                <Downvote
                  isAdmin={isAdmin}
                  isDownvotedState={isDownvotedState}
                  setIsDownvotedState={setIsDownvotedState}
                  setIsUpvotedState={setIsUpvotedState}
                  setUpvoteCount={setUpvoteCount}
                  setDownvoteCount={setDownvoteCount}
                  postId={postId}
                  userToken={userToken}
                />
              </div>
            </div>
            <div className="RightActions">
              <Bookmark
                postId={postId}
                isBookmarked={isBookmarked}
                onBookmarkClick={onBookmarkClick}
                setIsBookmarked={setIsBookmarked}
              />
              <Dots
                postId={postId}
                username={username}
                userToken={userToken}
                isAdmin={isAdmin}
                title={title}
                content={content}
                categories={categories}
                sentiment={sentiment}
                type={type}
                caption={caption}
                images={images}
                isQuotePost={isQuotePost}
                quotePostId={quotePostId}
                refreshFeed={refreshFeed}
                isComment={isComment}
                parentPostId={parentPostId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )}
</div>

  );
};

export default Post;
