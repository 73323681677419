import React, { useRef} from 'react';
import '../styles/Overview.css';
import laptop1 from '../assets/laptop1.svg';
import laptop2 from '../assets/laptop2.svg';
import laptop3 from '../assets/laptop3.svg';
import laptop4 from '../assets/laptop4.svg';
import laptop5 from '../assets/laptop5.svg';
import laptop6 from '../assets/laptop6.svg';
import laptop7 from '../assets/laptop7.svg';
import laptop8 from '../assets/laptop8.svg';
import cloudRevGraph from '../assets/cloudRevenuePictured.png';
import cloudGrowGraph from '../assets/cloudRevenueGrowth.png';
import investor1 from '../assets/davidSacks.png';
import investor2 from '../assets/nancyPelosi.png';
import investor3 from '../assets/frankBlake.png';
import badge1 from '../assets/badge1.svg';
import badge2 from '../assets/badge2.svg';
import badge3 from '../assets/badge3.svg';
import badge4 from '../assets/badge4.svg';
import spotify from '../assets/spotifyLogo.png';
import rhood from '../assets/rhood.svg';
import pfp from '../assets/pfp.svg';
import newsImage1 from '../assets/newsImage1.png';
import newsImage2 from '../assets/newsImage2.png';
import newsImage3 from '../assets/newsImage3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowUp, faArrowDown, faEllipsisH, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import blue from '../assets/blue.svg';
import green from '../assets/green.svg';
import green2 from '../assets/green2.svg';
import red from '../assets/red.svg';
import yellow from '../assets/yellow.svg';
import lime from '../assets/lime.svg';
import darkred from '../assets/darkred.svg';
import mentionsGraph from '../assets/mentionsGraph.png';
import ratingsGraph from '../assets/ratingsGraph.png';
import analysisSnapshot from '../assets/analysisSnapshot.png';
import award1 from '../assets/award1.png';
import award2 from '../assets/award2.png';
import award3 from '../assets/award3.png';
import award4 from '../assets/award4.png';
import portfolioChart from '../assets/portfolioChart.png';
import rocket from '../assets/rocketspace.svg';
import retweet from '../assets/retweet.svg';
import upvote from '../assets/upvote.svg';
import downvote from '../assets/downvote.svg';  
import comment from '../assets/comment.svg';  
import dots from '../assets/3dots.svg';  
import greenBookmark from '../assets/greenBookmark.svg';
import p1 from '../assets/colorDots/p1.svg';
import p2 from '../assets/colorDots/p2.svg';
import p3 from '../assets/colorDots/p3.svg';
import p4 from '../assets/colorDots/p4.svg';
import p5 from '../assets/colorDots/p5.svg';
import p6 from '../assets/colorDots/p6.svg';
import p7 from '../assets/colorDots/p7.svg';
import p8 from '../assets/colorDots/p8.svg';
import p9 from '../assets/colorDots/p9.svg';
import p10 from '../assets/colorDots/p10.svg';
import p11 from '../assets/colorDots/p11.svg';
import p12 from '../assets/colorDots/p12.svg';
import p13 from '../assets/colorDots/p13.svg';
import p14 from '../assets/colorDots/p14.svg';
import p15 from '../assets/colorDots/p15.svg';
import p16 from '../assets/colorDots/p16.svg';
import p17 from '../assets/colorDots/p17.svg';
import p18 from '../assets/colorDots/p18.svg';
import p19 from '../assets/colorDots/p19.svg';
import p20 from '../assets/colorDots/p20.svg';
import pills from '../assets/pills.svg';
import coinbase from '../assets/coinbase.svg';
import dogecoin from '../assets/dogecoin.svg';
import moonshots from '../assets/moonshots.svg';
import vestrPfp from '../assets/vestrPfp.svg';
import rocketPfp from '../assets/rocketPfp.svg'; 
import alexSaakBronze from '../assets/alexSaakBronze.svg'; 
import alexHumphriesSilver from '../assets/alexHumphriesSilver.svg'; 
import johnHannonGold from '../assets/johnHannonGold.svg'; 
import johnHannon from '../assets/johnHannon.svg';
import carolineKeutzer from '../assets/carolineKeutzer.svg';
import vestrMan from '../assets/vestrMan.svg';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
export default function Overview() {
  const navigate = useNavigate();
  const topRef = useRef(null);

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="Overview" ref = {topRef}>
   
      <div className="ov-overview-container">
      <h1 className="ov-vestr-logo bold" style={{ marginBottom: '1rem' }}>
  Vestr
  <span className="ov-16 medium" 
  style={{ color: '#808080', 
  background: 'transparent', 
  WebkitBackgroundClip: 'initial', 
  WebkitTextFillColor: 'initial',
  marginLeft: '0.5rem'}}>
    Beta
  </span>
</h1>


        <div className="ov-mrvestr-inputs">
        <div onClick={() => navigate('/mrvestr')} className="ov-question-box">
          <span className="ov-overview-subtitle">Ask Mr. Vestr any finance question!</span>
        </div>
        <div onClick={() => navigate('/mrvestr')}className="ov-ask-button" > 
        <FontAwesomeIcon icon={faArrowRight} /> 
        </div>
        </div>
        <div className="ov-questions-container">
          <button onClick={() => navigate('/mrvestr')}className="ov-question-button ov-16 ">Summarize Spotify's latest conference call.</button>
          <button onClick={() => navigate('/mrvestr')}className="ov-question-button ov-16">Write me a slide deck outline for ASML.</button>
          <button onClick={() => navigate('/mrvestr')}className="ov-question-button ov-16">How is EQB Inc managing its loan losses as of late?</button>
          <button onClick={() => navigate('/mrvestr')}className="ov-question-button ov-16">How has Meta's tone about the metaverse changed over time in earnings calls?</button>
        </div>
      </div>

      <div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <h2 className="ov-introduction-title bold">The future of investment research, powered by AI.</h2>
          <hr className="ov-introduction-divider" style = {{width: 'calc(100% + 6.5rem)'}}/>
          <p className="ov-introduction-subtitle ov-16 bold">A new standard for fundamental research.</p>
          <p className="ov-introduction-description ov-16">
            Get institutional-quality data that's been verified by human equity analysts.
            Trusted, in-depth information helps you capitalize on opportunities.
          </p>
        </div>
        <div className="ov-introduction-image">
          <img src={laptop1} alt="Laptop displaying Vestr" />
        </div>
      </div>

      <div className="ov-data-section">
        <div className="ov-data-card">
          <div className="ov-card-header">
            <button className="ov-card-button ov-16">Summarize Microsoft's last quarter</button>
            <img src={pfp} alt="Avatar" className="ov-card-avatar" style = {{marginLeft: '0.5rem'}}/>
          </div>
          <div className="ov-card-content-wrapper" style={{ display: 'flex' }}>
            <img src={vestrMan} alt="Avatar" className="ov-card-avatar" style={{ alignSelf: 'flex-end', marginRight: '0.5rem' }} />
            <div className="ov-card-content ov-16">
              <p style = {{marginTop: 0}}>Microsoft experienced growth in various segments, including a 22% YoY increase in Cloud revenue (pictured) to $31.8 billion.</p>
              <img src={cloudRevGraph} alt="Cloud Revenue Graph" className="ov-graph-image" />
              <p className='ov-16'><span className="bold">Revenue:</span> $61.9 billion, an increase of 17% year-over-year [source]</p>
              <p className='ov-16'><span className="bold">Operating Income:</span> $27.6 billion, an increase of 23% year-over-year [source]</p>
            </div>
          </div>
        </div>
        <div className="ov-data-card">
          <div className="ov-card-header">
            <button className="ov-card-button ov-16">Compare Microsoft's cloud business to Amazon's</button>
            <img src={pfp} alt="Avatar" className="ov-card-avatar" style= {{marginLeft: '0.5rem'}}/>
          </div>
          <div className="ov-card-content-wrapper" style={{ display: 'flex' }}>
            <img src={vestrMan} alt="Avatar" className="ov-card-avatar" style={{ alignSelf: 'flex-end', marginRight: '0.5rem'}} />
            <div className="ov-card-content ov-16">
              <p style = {{marginTop: 0}}>Microsoft's Intelligent Cloud revenue grew by 19.03% over the last twelve months, while AWS revenue grew by 13.77% over the same period.</p>
              <p className = "ov-colors"><span><object aria-label="color" type="image/svg+xml" data={blue} className="ov-color" /> Amazon</span> <span><object aria-label="color" type="image/svg+xml" data={green} className="ov-color" />  Microsoft</span></p>
              <img src={cloudGrowGraph} alt="Cloud Growth Graph" className="ov-graph-image" />
            </div>
          </div>
        </div>

        <div className="ov-info-card">
          <p className="ov-info-text ov-16">
            Get better data for better decisions and work faster than ever.
          </p>
          <p className="ov-info-text ov-16">
            Save time researching your next great investment idea with the comprehensive investment research platform for global equities. This is where institutional-level data meets the speed of AI.
          </p>
        </div>
      </div>

      <div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">Find the best investors with our leaderboards.</h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
            Discover the top-performing investors on Vestr with daily leaderboards. Search by category, reviews, returns, subscription offerings, location, and more.
          </p>
        </div>
        <div className="ov-introduction-image">
          <img src={laptop2} alt="Laptop displaying Vestr" />
        </div>
      </div>

      <div className = "ov-investors-section">
  <div className="ov-top-investors">
    <h2 className="ov-top-text bold ov-16">Today's Top Investors</h2>
    <div className="ov-row-investors">
        <div className="ov-investor ov-13">
            <div className="ov-investor-col1">
                <img src={investor1} alt="David Sacks" className="ov-investor-pic" />
                <span className="ov-number ov-13">#1</span>
                <div className="ov-badge-row">
                <object aria-label="badge" type="image/svg+xml" data={badge1} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge2} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge3} className="ov-badge" />
                </div>
                <button className="ov-follow-btn bold ov-13">Follow</button>
            </div>
            <div className="ov-investor-col2">
                <h3 className="ov-nameInvestr bold ov-16">David Sacks</h3>
                <div className="ov-investor-col2-stats">
                <p className="ov-investor-stat ov-green ov-13 bold">48.22% <span className="ov-stat-label medium">today</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">99.12% <span className="ov-stat-label medium">90-day</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">1,306.21% <span className="ov-stat-label medium">all time</span></p>
                </div>
                <div className="ov-investor-col2-stats-2">
                <p className="ov-investor-details ov-13 bold">49.54k <span className="ov-stat-label medium">subscribers</span></p>
                <p className="ov-investor-details ov-13 bold">4.97 <span className="ov-stat-label medium">avg. review</span></p>
                </div>
                
                <p className="ov-investor-positions ov-13">
                    <span className="bold">
                        <span className="medium">Largest positions: </span> AAPL, MSFT, GOOG, AMZN<br />
                        $159.5 Call 5/24
                    </span>
                </p>
            </div>
        </div>

        <div className="ov-investor ov-13">
            <div className="ov-investor-col1">
                <img src={investor2} alt="Nancy Pelosi" className="ov-investor-pic" />
                <span style={{ backgroundColor: '#B3B3B3' }} className="ov-number ov-13">#2</span>
                <div className="ov-badge-row">
                <object aria-label="badge" type="image/svg+xml" data={badge2} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge4} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge3} className="ov-badge" />
                
                </div>
                <button className="ov-follow-btn bold ov-13">Follow</button>
            </div>
            <div className="ov-investor-col2">
                <h3 className="ov-nameInvestr bold ov-16">Nancy Pelosi</h3>
                <div className="ov-investor-col2-stats">
                <p className="ov-investor-stat ov-green ov-13 bold">28.22% <span className="ov-stat-label medium">today</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">55.55% <span className="ov-stat-label medium">90-day</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">32,106.21% <span className="ov-stat-label medium">all time</span></p>
                </div>
                <div className="ov-investor-col2-stats-2">
                <p className="ov-investor-details ov-13 bold">122.54k <span className="ov-stat-label medium">subscribers</span></p>
                <p className="ov-investor-details ov-13 bold">4.01 <span className="ov-stat-label medium">avg. review</span></p>
                </div>
                <p className="ov-investor-positions ov-13">
                    <span className="bold">
                        <span className="medium">Largest positions: </span> PANW, BA, AA, MSFT, GOOG, AMZN, AAPL, BTC
                    </span>
                </p>
            </div>
        </div>

        <div className="ov-investor ov-13">
            <div className="ov-investor-col1">
                <img src={investor3} alt="Frank Blake" className="ov-investor-pic" />
                <span style={{ backgroundColor: '#CD7F32' }} className="ov-number">#3</span>
                <div className="ov-badge-row">
                <object aria-label="badge" type="image/svg+xml" data={badge2} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge4} className="ov-badge" />
                <object aria-label="badge" type="image/svg+xml" data={badge3} className="ov-badge" />
                </div>
                <button className="ov-follow-btn bold ov-13">Follow</button>
            </div>
            <div className="ov-investor-col2">
                <h3 className="bold ov-16 ov-nameInvestr">Frank Blake</h3>
                <div className="ov-investor-col2-stats">
                <p className="ov-investor-stat ov-green ov-13 bold">18.22% <span className="ov-stat-label medium">today</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">99.12% <span className="ov-stat-label medium">90-day</span></p>
                <p className="ov-investor-stat ov-green ov-13 bold">3,444.21% <span className="ov-stat-label medium">all time</span></p>
                </div>
                <div className="ov-investor-col2-stats-2">
                <p className="ov-investor-details ov-13 bold">88.57k <span className="ov-stat-label medium">subscribers</span></p>
                <p className="ov-investor-details ov-13 bold">4.23 <span className="ov-stat-label medium">avg. review</span></p>
                </div>
                <p className="ov-investor-positions ov-13">
                    <span className="bold">
                        <span className="medium">Largest positions: </span> MSFT, GOOG, NVDA, AAPL, UA, UCO, USO
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>

        <div className = "ov-invest-text ov-16">
            <p>
            Vestr <span className = "bold">fixes the lack of transparency</span> and accountability on traditional social media by making returns and reviews public.

            </p>
            <p>
            Follow investors across all industries. Track politicians and hedge funds. Badges are awarded to investors based off portfolio accomplishments.

            </p>

        </div>


      </div>
      <div className= "ov-intro-wrapper">

      
      <div  className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">Manage your real and simulated portfolios in one spot.</h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
          Link all your financial accounts (brokerage, checking, savings, retirement, crypto wallets) and monitor both real and simulated portfolios effortlessly, giving you a <span className="bold">comprehensive view of your net worth.</span>
          </p>
        </div>
        <div className="ov-introduction-image">
          <img src={laptop3} alt="Laptop displaying Vestr" />
        </div>
        
      </div>

      <div className="ov-portfolio-section">
          <div className ="ov-portfolio-container">
            <div className = "ov-portfolios-row ov-16">
            <div className="ov-portfolio-col1">
      <h2 className="bold ov-16" style={{ textAlign: 'left', margin: 0 }}>Portfolios</h2>
      <div className="ov-portfolio-entry-wrapper">
        <div className="ov-portfolio-col1">
          <div className="ov-portfolio-entry">
            <object aria-label="Vestr Main" type="image/svg+xml" data={vestrPfp} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Vestr Main</h3>
              <span style={{ textAlign: 'left' }}>$32,248,909.80</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">29.43% </span><span>past year</span></span>
            </div>
          </div>
          <div className="ov-portfolio-entry">
            <object aria-label="Robinhood" type="image/svg+xml" data={rhood} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Robinhood</h3>
              <span style={{ textAlign: 'left' }}>$248,909.80</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">31.55% </span><span>past year</span></span>
            </div>
          </div>
          <div className="ov-portfolio-entry">
            <object aria-label="Coinbase" type="image/svg+xml" data={coinbase} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Coinbase</h3>
              <span style={{ textAlign: 'left' }}>$28,554,775.98</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">34.66% </span><span>past year</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="ov-portfolio-col2">
      <h2 className="bold ov-16" style={{ textAlign: 'left', margin: 0 }}>Simulated portfolio</h2>
      <div className="ov-portfolio-entry-wrapper">
        <div className="ov-portfolio-col2">
          <div className="ov-portfolio-entry">
            <object aria-label="Pharma + Health" type="image/svg+xml" data={pills} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Pharma + Health</h3>
              <span style={{ textAlign: 'left' }}>$70,248,909.80</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">134.55% </span><span>past year</span></span>
            </div>
          </div>
          <div className="ov-portfolio-entry">
            <object aria-label="Moonshots" type="image/svg+xml" data={moonshots} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Moonshots</h3>
              <span style={{ textAlign: 'left' }}>$248,909.80</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">14.55% </span><span>past year</span></span>
            </div>
          </div>
          <div className="ov-portfolio-entry">
            <object aria-label="Meme Coins" type="image/svg+xml" data={dogecoin} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16">Meme Coins</h3>
              <span style={{ textAlign: 'left' }}>$8,554,775.98</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">39.99% </span><span>past year</span></span>
            </div>
          </div>
          <div className="ov-portfolio-entry">
            <object aria-label="Defense Companies" type="image/svg+xml" data={rocketPfp} className="ov-portfolio-img" />
            <div className="ov-portfolio-text">
              <h3 className="bold ov-16" style = {{whiteSpace: 'nowrap'}}>Defense Companies</h3>
              <span style={{ textAlign: 'left' }}>$8,554,775.98</span>
              <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}> <span className="ov-green bold">34.55% </span><span>past year</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

            </div>
            <div className="ov-portfolios-recent ov-16">
      <h2 className ="ov-16 bold" style={{ margin: 0 }}>Recent trades:</h2>

      <div className="ov-trade-card green-background">
        <div className="ov-trade-header">
          <span className="ov-trade-type bold">GME buy</span>

          <span className="ov-trade-time ov-13">9/11/24 9:00 am <FontAwesomeIcon icon={faEllipsisH}  style={{marginLeft: '0.25rem'}}/>
          </span>
          
        </div>
        <div className="ov-trade-body" >
          <div className="ov-trade-row bold ov-13">
            <div className="ov-trade-label">Order</div>
            <div className="ov-trade-label">Price</div>
            <div className="ov-trade-label">Return</div>
          </div>
          <div className="ov-trade-row bold">
            <div className="ov-trade-value bold">GME $26 Call 11/19</div>
            <div className="ov-trade-value bold">$11.75</div>
            <div className="ov-trade-value bold green-text">+7.55%</div>
          </div>
        </div>
      </div>

      <div className="ov-trade-card ov-green-outline" style = {{backgroundColor: 'transparent'}}>
        <div className="ov-trade-header">
          <span className="ov-trade-type bold">BTC sell</span>
          <span className="ov-trade-time ov-13">9/7/24 08:59 am <FontAwesomeIcon icon={faEllipsisH}  style={{marginLeft: '0.25rem'}}/></span>
        </div>
        <div className="ov-trade-body">
          <div className="ov-trade-row bold ov-13">
            <div className="ov-trade-label">Order</div>
            <div className="ov-trade-label">Price</div>
            <div className="ov-trade-label">Price since</div>
          </div>
          <div className="ov-trade-row bold">
            <div className="ov-trade-value bold">BTC</div>
            <div className="ov-trade-value bold">$72,344.16</div>
            <div className="ov-trade-value bold red-text">-6.24%</div>
          </div>
        </div>
      </div>

      <div className="ov-trade-card ov-red-outline" style = {{backgroundColor: 'transparent'}} >
        <div className="ov-trade-header">
          <span className="ov-trade-type bold">PANW sell</span>
          <span className="ov-trade-time ov-13">9/6/24 4:50 pm <FontAwesomeIcon icon={faEllipsisH}  style={{marginLeft: '0.25rem'}}/></span>
        </div>
        <div className="ov-trade-body">
          <div className="ov-trade-row bold ov-13">
            <div className="ov-trade-label">Order</div>
            <div className="ov-trade-label">Price</div>
            <div className="ov-trade-label">Price since</div>
          </div>
          <div className="ov-trade-row bold">
            <div className="ov-trade-value bold">PANW</div>
            <div className="ov-trade-value bold">$311.75</div>
            <div className="ov-trade-value bold green-text">+1.22%</div>
          </div>
        </div>
      </div>

    </div>



              <div className = "ov-portfolios-info ov-16">
                <p>
                Keep your portfolios private or choose to share them with friends or the public. <span className='bold'>
                Either way, effortlessly monitor your net worth on Vestr.</span>
                </p>
                <p>
                Share your trades with the three dots at the top corner of the trade cards. The level of green/red denotes how profitable the trade has been.
                </p>

              </div>


          </div>
          </div>
      </div>
    




      <div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">Join public and private discussion rooms.</h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
          Engage with like-minded investors in rooms, offering both private and public spaces for insightful discussions and networking. Compete with other room members in the room rankings.
          </p>
        </div>
        <div className="ov-introduction-image">
          <img src={laptop4} alt="Laptop displaying Vestr" />
        </div>
      </div>


    <div className= "ov-spotify-section">
    <div className="ov-spotify-container">
  <div className="ov-spotify-info ov-16">
    <div className="ov-spotify-header">
      <img src={spotify} alt="Spotify Logo" className="ov-spotify-logo" />
      <div className="ov-spotify-details">
        <h3 className='bold ov-24'>Spotify <FontAwesomeIcon icon={faCheckCircle} className="ov-verified" /></h3>
        <span>r/spotify</span>
      </div>
    </div>
    <div className="ov-spotify-buttons">
      <button className="ov-spotify-joined-btn bold ov-16">Joined</button>
      <button className="ov-spotify-audio-btn bold ov-16">Join audio</button>
    </div>
    <hr className="ov-divider-spo" />
    <div className="ov-spotify-stats">
      <div className="ov-spotify-stat">
        <h4 className="bold ov-20">2.8m</h4>
        <span >members</span>
      </div>
      <div className="ov-spotify-stat">
        <h4 className="bold ov-20">315k</h4>
        <span >active</span>
      </div>
      <div className="ov-spotify-stat">
        <h4 className="bold ov-20">#1</h4>
        <span >in music</span>
      </div>
    </div>
    <hr className="ov-divider-spo" />
    <div className="ov-room-rankings">
      <h4 className="bold ov-16" style = {{textAlign: 'left'}}>Room rankings</h4>
      <ul>
        <li>
          <span className="ov-rank-number">1</span>
          <object aria-label="John Hannon" type="image/svg+xml" data={johnHannonGold} className="ov-rank-avatar" />
          <span className="ov-rank-name bold">John Hannon</span>
        </li>
        <li>
          <span className="ov-rank-number">2</span>
          <object aria-label="Alex Humphries" type="image/svg+xml" data={alexHumphriesSilver} className="ov-rank-avatar" />
          <span className="ov-rank-name bold">Alexander Humphries</span>
        </li>
        <li>
          <span className="ov-rank-number">3</span>
          <object aria-label="Alex Saak" type="image/svg+xml" data={alexSaakBronze} className="ov-rank-avatar" />
          <span className="ov-rank-name bold">Alexander Saak</span>
        </li>
      </ul>
    </div>
  </div>
  <div className="ov-spotify-comments">
  <div className="ov-comment-card">
    <div className="ov-comment-header ov-16">
    <object aria-label="John Hannon" type="image/svg+xml" data={johnHannon} className="ov-comment-avatar" />
      <div className="ov-comment-details">
        <h4 className="bold">John Hannon <span className="regular">@Hannon · 1h</span> <FontAwesomeIcon icon={faEllipsisH}  style={{marginLeft: '1rem'}}/></h4>
        <p className="ov-comment-content">Just a few weeks ago, we at Universal announced the <span className="ov-highlight">Spotify</span> Open Access Platform, which gives creators more leverage with fans. Creators can start earning off their subscriptions, just like Vestr. Copy cats!!!</p>
        <p className="ov-comment-replies ov-13 bold">Reply (1.3k replies)</p>
      </div>
      <div className="ov-comment-votes">
      <FontAwesomeIcon icon={faArrowUp} className="ov-vote-up-green"/>
        <span className="ov-vote-count regular" style = {{color: '#1DCF5B'}}>111.3k</span>
        <FontAwesomeIcon icon={faArrowDown} className="ov-vote-down" />
      </div>
    </div>
  </div>
<hr className="ov-divider-spo" />
  <div className="ov-comment-card">
    <div className="ov-comment-header ov-16" style = {{marginTop:'0.75rem'}}>
    <object aria-label="Caroline Keutzer" type="image/svg+xml" data={carolineKeutzer} className="ov-comment-avatar" />
      <div className="ov-comment-details">
        <h4 className="bold">Caroline Keutzer <span className="regular">@Shadow · 1h</span> <FontAwesomeIcon icon={faEllipsisH} style={{marginLeft: '1rem'}}/></h4>
        <p className="ov-comment-content">Just read that <span className="highlight">Spotify</span> is planning to expand its podcasting platform significantly over the next year. This move could potentially drive up user engagement and subscription rates. With podcasts becoming an increasingly popular medium, I like the stock!!! Does anyone have insights or data on how this expansion might impact their stock performance? Would love to hear your thoughts and analysis! Cheers!</p>
        <p className="ov-comment-replies ov-13 bold">Reply (995 replies)</p>
      </div>
      <div className="ov-comment-votes">
      <FontAwesomeIcon icon={faArrowUp} className="ov-vote-up" />
        <span className="ov-vote-count regular">89.1k</span>
        <FontAwesomeIcon icon={faArrowDown} className="ov-vote-down" />
      </div>
    </div>
  </div>
</div>

</div>
    </div>


<div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">Follow investors, topics, friends on your feed</h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
          Vestr offers a comprehensive social feed where you can follow your favorite topics, assets, and investors. Use specialized feeds: Subscribed, Following, and Everything. 

          </p>
          <p className="ov-introduction-description ov-16">
          Filter posts by trades, news, research, or general. Further filter posts by timeframe, keyword, and industry. Sort by most recent, popularity, and most commented.
          </p>


        </div>
        <div className="ov-introduction-image">
          <img src={laptop5} alt="Laptop displaying Vestr" />
        </div>
      </div>
    <div className= "ov-gme-section">


      <div className="ov-gme-container">
  <div className="ov-gme-post">
  <object aria-label="pfp" type="image/svg+xml" data={pfp} className="ov-gme-post-pic" /> 
    <div className="ov-gme-post-header ov-15">
      <div><span className="bold">Roaring Kitty </span><FontAwesomeIcon icon={faCheckCircle} className="ov-gme-verified" /> <span style ={{color:'#707070'}}>@deepfuckingvalue · 43m · Buy order</span> · <span className="bold">$GME</span> · <span className="ov-green bold">Positive</span></div>
      <p className="ov-gme-post-content ov-15">Watch this...</p>
    </div>
    <div className="ov-gme-post-details green-background">
      <div className="ov-gme-row">
        <h4 className="bold ov-15">$GME buy order</h4>
      </div>
      <div className="ov-gme-row">
        <div className="ov-gme-detail">
          <span className="ov-gme-label">Quantity:</span>
          <span className="ov-gme-value ov-15 bold">2,330</span>
        </div>
        <div className="ov-gme-detail">
          <span className="ov-gme-label">Order:</span>
          <span className="ov-gme-value ov-15 bold">GME $26 Call 11/19</span>
        </div>
        <div className="ov-gme-detail">
          <span className="ov-gme-label">Price:</span>
          <span className="ov-gme-value ov-15 bold">$11.75</span>
          <span className="ov-gme-value ov-15 bold ov-gme-green">+31.24% today</span>
        </div>
        <div className="ov-gme-detail">
          <span className="ov-gme-label">Return:</span>
          <span className="ov-gme-value ov-15 bold ov-gme-green">+31.24% since purchase</span>
        </div>
        <div className="ov-gme-detail">
          <span className="ov-gme-label">% of portfolio:</span>
          <span className="ov-gme-value ov-15 bold">N/A</span>
        </div>
        <button className="ov-gme-copy-trade-btn bold ov-15">Copy trade</button>
      </div>
    </div>
    <div className ="ov-gme-action-items">
    {/* Borrowing from Post.css */}
    <div className="LeftActions" style = {{width: '50%'}}>
  <div className="ActionItem" style={{ gap: '0.5rem', width: '8rem', justifyContent: 'left' }}>
    <object aria-label="comment" type="image/svg+xml" data={comment} className="ActionIcon" /> 220k
  </div>
  <div className="ActionItem" style={{ gap: '0.5rem', width: '8rem',justifyContent: 'left' }}>
    <object aria-label="repost" type="image/svg+xml" data={retweet} className="ActionIcon" /> 97k
  </div>
  <div className="UpvoteDownvoteContainer" style = {{width: '12rem' }}>
    <div className="ActionItem2">
      <object aria-label="upvote" type="image/svg+xml" data={upvote} className="ActionIcon" />
    </div>
    <span>1.5m</span>
    <div className="ActionItem2">
      <object aria-label= "downvote" type="image/svg+xml" data={downvote} className="ActionIcon" />
    </div>
  </div>
</div>
<div className="RightActions">
  <div className="ActionItem">
    <object aria-label= "bookmark" type="image/svg+xml" data={greenBookmark} className="Bookmarked" />
  </div>
  <div className="ActionItem">
    <object aria-label= "action" type="image/svg+xml" data={dots} className="ActionIcon" />
  </div>
</div>


    </div>
    


  </div>

  <div className="ov-gme-post-text ov-16">
    <p>
      <span className="bold">
       Copy trades right from your feed.  </span>
       Follow or subscribe to your favorite investors to see when they post or execute a trade.
    </p>
  </div>
</div>
</div>



<div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">Get the latest from all major news sources in one place.</h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
         <span className ="bold">
         Vestr syndicates news from major publications and categorizes them. 
         A custom “for you” news page created by A.I. </span>
           includes summaries of major news regarding your portfolio.
          </p>
          <p className="ov-introduction-description ov-16">
          Upvote, downvote, repost, bookmark and share stories on the fly. 
          </p>


        </div>
        <div className="ov-introduction-image">
          <img src={laptop6} alt="Laptop displaying Vestr" />
        </div>
      </div>
  <div className="ov-news-section">


<div className="ov-news-container"> 
  <div className="ov-news-card">
    <img src={newsImage1} alt="Russia China news" className="ov-news-image" />
    <h2 className='ov-news-header ov-24 bold'>
    For China, Russia Is Both a Partner and a Predicament 
    </h2>
    <p className="ov-news-description ov-15">
    For Chinese leader Xi Jinping, Russian President Vladimir Putin is a useful partner in opposing a U.S.-dominated world order.
    But the relationship also represents a liability for China as American and European officials warn Beijing against aiding Russia’s efforts to rebuild its recovering military.
      </p>

      <span className ="ov-news-publisher ov-13"><span className="bold">WSJ </span> - 44 mins ago </span>
      <div className ="ov-news-stock bold">
        <span className = "ov-13 bold">BA <span className='bold ov-green'>(2.34%)</span> </span>
      </div>
      <div className ="ov-news-actions">
        <div className= "UpvoteDownvoteContainer">

     
      <div className="ov-news-action">
      <object aria-label="upvote" type="image/svg+xml" data={upvote} className="ActionIcon" /> 
        </div>
        <div className="ov-news-action ov-15">
        15.1k
        </div>
        <div className="ov-news-action">
        <object aria-label="downvote" type="image/svg+xml" data={downvote} className="ActionIcon" /> 
        </div>
        </div>
        <div className="ov-news-action ov-15">
        <object aria-label="repost" type="image/svg+xml" data={retweet} className="ActionIcon" /> 3.54k
        </div>
      
        <div className="ov-news-action ov-bookmarked">
        <object aria-label="bookmark" type="image/svg+xml" data={greenBookmark} className="ActionIcon" />
        </div>
        <div className="ov-news-action">
        <object aria-label="action" type="image/svg+xml" data={dots} className="ActionIcon" />
        </div>

      </div>
    </div>

    <div className="ov-news-card">
    <img src={newsImage2} alt="NBA news" className="ov-news-image" />
    <h2 className='ov-news-header ov-24 bold'>
    NBA Unveils $77 Billion TV and Streaming Deals With NBC, ESPN and Amazon
    </h2>
    <p className="ov-news-description ov-15">
    The National Basketball Association said it has signed landmark media-rights deals worth $77 billion with Disney’s ESPN, Amazon and Comcast’s NBCUniversal, turning away a last minute bid from current rights holder Warner Bros. Discovery.
      </p>

      <span className ="ov-news-publisher ov-13"><span className="bold">WSJ </span> - 44 mins ago </span>
      <div className ="ov-news-stock bold ov-13">
        <span className="bold">DIS <span className='bold ov-green'>(1.34%)</span> </span>
        <span className="bold">AMZN <span className='bold ov-green'>(5.05%)</span> </span>
        <span className="bold">CMSCA <span className='bold ov-green'>(1.00%)</span> </span>
        <span className="bold">WBD <span className='bold ov-red'>(-4.66%)</span> </span>
      
      
      </div>
      <div className ="ov-news-actions">
        <div className='UpvoteDownvoteContainer'>

      <div className="ov-news-action">
      <object aria-label="upvote" type="image/svg+xml" data={upvote} className="ActionIcon" /> 
        </div>
        <div className="ov-news-action ov-15">
        17.8k
        </div>
        <div className="ov-news-action">
        <object aria-label="downvote" type="image/svg+xml" data={downvote} className="ActionIcon" /> 
        </div>
        </div>
        <div className="ov-news-action ov-15">
        <object aria-label="repost" type="image/svg+xml" data={retweet} className="ActionIcon" /> 5.3k
        </div>
      
        <div className="ov-news-action ov-bookmarked">
        <object aria-label="bookmark" type="image/svg+xml" data={greenBookmark} className="ActionIcon" />
        </div>
        <div className="ov-news-action">
        <object aria-label="action" type="image/svg+xml" data={dots} className="ActionIcon" />
        </div>

      </div>
    </div>
    <div className="ov-news-card">
    <img src={newsImage3} alt="Rupert Murdoch news" className="ov-news-image" />
    <h2 className='ov-news-header ov-24 bold'>
    Rupert Murdoch Plan to Give Control to Son Lachlan Triggers Family Legal Battle
    </h2>
    <p className="ov-news-description ov-15">
    Rupert Murdoch is engaged in a legal fight with some of his children, as he tries to hand control of his media empire to his eldest son, Lachlan, a battle with major ramifications for the future of the mogul’s two companies.
      </p>

      <span className ="ov-news-publisher ov-13">
        <span className="bold">Bloomberg </span > - 44 mins ago </span>
      <span className="bold">MSFT <span className='bold ov-green'>(0.88%)</span> 
      
      </span>


      <div className ="ov-news-actions ov-14">
        <div className='UpvoteDownvoteContainer'>
        <div className="ov-news-action">
      <object aria-label="upvote" type="image/svg+xml" data={upvote} className="ActionIcon" /> 
        </div>
        <div className="ov-news-action ov-15">
        44.9k
        </div>
        <div className="ov-news-action">
        <object aria-label="downvote" type="image/svg+xml" data={downvote} className="ActionIcon" /> 
        </div>
        </div>

       
        <div className="ov-news-action ov-15">
        <object aria-label="repost" type="image/svg+xml" data={retweet} className="ActionIcon" /> 12.44k
        </div>
      
        <div className="ov-news-action ov-bookmarked">
        <object aria-label="bookmark" type="image/svg+xml" data={greenBookmark} className="ActionIcon" />
        </div>
        <div className="ov-news-action">
        <object aria-label="action" type="image/svg+xml" data={dots} className="ActionIcon" />
        </div>

      </div>
    </div>


  </div>
  </div>

<div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">
          Access live market data and unique insights.


          </h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
          Stay ahead with Vestr’s live stock and crypto market data. Compare assets, read full financial reports, weigh public sentiment, and buy <span className="bold">research from professional firms and investors.</span>
          </p>
          <p className="ov-introduction-description ov-16">
          Engage with other investors directly using the embedded rooms feature, allowing you to monitor the community while researching assets.
          </p>


        </div>
        <div className="ov-introduction-image">
          <img src={laptop7} alt="Laptop displaying Vestr" />
        </div>
      </div>


<div className="ov-market-section">
      <div className="ov-market-container">       
          <div className = "ov-market-public">
            <h2 className="bold ov-24" style = {{margin: 0}}>Public</h2>
            <div className = "ov-market-public-row">
              <div className = "ov-market-public-col1 ov-16">
                <h3 className = "bold ov-16">Buy Rating</h3>
                <div style = {{display: 'flex', alignItems: 'center'}}><button className="ov-market-col-btn ov-16"> <span className="bold ov-16">3 months</span> <FontAwesomeIcon icon={faChevronDown} />
                </button> <span className = "ov-green bold ov-16" style = {{marginLeft: '0.5rem', fontSize: '1.2rem'}}>+5.23%</span> </div>
                <p className = "ov-colors" style = {{gap:'2rem'}}><span><object aria-label="green" type="image/svg+xml" data={green} className="ov-color" /> Analysts</span> <span><object aria-label="blue" type="image/svg+xml" data={blue} className="ov-color" /> Public</span></p>
                <img src = {ratingsGraph} alt = "ratings graph" className = "ov-market-col-graph"/>
                <span className ="ov-13" style = {{alignSelf: 'center'}}>9/23/24 - 8.50 Public, 8.55 Analysts</span>
              </div>
              <div className = "ov-market-public-col1 ov-16">
                <h3 className= "bold ov-16">Mentions</h3>
                <div style = {{display: 'flex', alignItems: 'center'}}><button className="ov-market-col-btn ov-16"> <span className="bold ov-16">3 months</span> <FontAwesomeIcon icon={faChevronDown} />
                </button> <span className = "ov-green bold ov-16" style = {{marginLeft: '0.5rem', fontSize: '1.2rem'}}>+17.23%</span> </div>
                <p className = "ov-colors" style = {{gap:'2rem'}}><span><object aria-label="green" type="image/svg+xml" data={green} className="ov-color" /> Posts</span> <span><object aria-label="blue" type="image/svg+xml" data={blue} className="ov-color" /> Rooms</span></p>
                <img src = {mentionsGraph} alt = "mentions graph" className = "ov-market-col-graph"/>
                <span className ="ov-13" style = {{alignSelf: 'center'}}>9/23/24 - 123k mentions / day</span>
              </div>

            </div>

          </div>
          <div className = "ov-market-analysis">
            <h2 className="bold ov-24" style = {{margin: 0}}>Analysis</h2>
            <h3 className="bold ov-16">Snapshot Opinion</h3>
            <div className ="ov-market-analysis-row">
              <div className= "ov-market-analysis-col1">
                <img src = {analysisSnapshot} alt = "wheel1" className = "ov-market-analysis-wheel"/>

              </div>
              <div className= "ov-market-analysis-col2 ov-13">
                  <div className="ov-market-analysis-buy">
                  <object aria-label="green2" type="image/svg+xml" data={green2} className="ov-market-analysis-color" />
                    <div style = {{display: 'flex', flexDirection:'column'}}>
                          <span className="bold">Strong buy</span>
                          <span>17 votes (25%)</span>
                    </div>
                  </div>
                  <div className="ov-market-analysis-buy">
                  <object aria-label="lime" type="image/svg+xml" data={lime} className="ov-market-analysis-color" />
                    <div style = {{display: 'flex', flexDirection:'column'}}>
                          <span className="bold" >Buy</span>
                          <span>28 votes (41%)</span>
                    </div>
                  </div>
                  <div className="ov-market-analysis-buy">
                  <object aria-label="yellow" type="image/svg+xml" data={yellow} className="ov-market-analysis-color" />
                    <div style = {{display: 'flex', flexDirection:'column'}}>
                          <span className="bold">Neutral</span>
                          <span>14 votes (20%)</span>
                    </div>
                  </div>
                  <div className="ov-market-analysis-buy">
                  <object aria-label="red" type="image/svg+xml" data={red} className="ov-market-analysis-color" />
                    <div style = {{display: 'flex', flexDirection:'column'}}>
                          <span className="bold">Sell</span>
                          <span>8 votes (12%)</span>
                    </div>
                  </div>
                  <div className="ov-market-analysis-buy">
                    <object aria-label="darkred" type="image/svg+xml" data={darkred} className="ov-market-analysis-color" />
                    <div style = {{display: 'flex', flexDirection:'column'}}>
                          <span className="bold">Strong sell</span>
                          <span>2 votes (3%)</span>
                    </div>
                  </div>

            </div>


            </div>

          </div>

        </div>
</div>

<div className="ov-introduction-container">
        <div className="ov-introduction-text">
          <span className="ov-introduction-subtitle ov-16 bold">Coming 2025</span>
          <h2 className="ov-introduction-title bold">
          Subscribe to investors based on returns and reviews.
          </h2>
          <hr className="ov-introduction-divider" />
          <p className="ov-introduction-description ov-16">
          Vestr brings <span className="bold">accountability and transparency with returns and reviews</span> - with detailed portfolio metrics and community reviews, you can make informed decisions about who to trust.  Follow for free or subscribe for exclusive content!
          </p>
        </div>
        <div className="ov-introduction-image">
          <img src={laptop8} alt="Laptop displaying Vestr" />
        </div>
      </div>


<div className="ov-subscription-section">
      <div className = "ov-subscription-container">
      <div className="ov-subscription-col1">
        <h2 className="ov-16 bold" style = {{margin: '0'}}>Subscription packages</h2>
        <div className="ov-subscription-package">
        <div  className="ov-subscription-details ov-16">
          <div className="ov-subscription-review-wrapper">
            <div className="ov-subscription-title bold">Base ($20 / month)</div>
            <ul className="ov-subscription-features">
              <li>Exclusive posts</li>
              <li>Hannon’s room</li>
              <li>Trade alerts</li>
            </ul>
            <div className="ov-subscription-rating">
              <span className="ov-subscription-stars">★★★★☆</span>
              <span className="ov-subscription-reviews ov-13">(4.67k)</span>
            </div>
          </div>
          <button className="ov-subscription-button bold ov-16">Buy</button>
        </div>
      </div>

      <div className="ov-subscription-package">
        <div className="ov-subscription-details ov-16">
          <div className="ov-subscription-review-wrapper">
            <div className="ov-subscription-title bold">Premium ($50 / month)</div>
            <ul className="ov-subscription-features">
              <li>Base package</li>
              <li>Daily emails</li>
              <li>Twenty minute phone call once a month</li>
            </ul>
            <div className="ov-subscription-rating">
              <span className="ov-subscription-stars">★★★★☆</span>
              <span className="ov-subscription-reviews ov-13">(1.2k)</span>
            </div>
          </div>
          <button className="ov-subscription-button bold ov-16">Buy</button>
        </div>
      </div>

      <div className="ov-subscription-package">
        <div className="ov-subscription-details ov-16">
          <div className="ov-subscription-review-wrapper">
            <div className="ov-subscription-title bold">Pro ($150 / month)</div>
            <ul className="ov-subscription-features">
              <li>Premium package</li>
              <li>Twenty minute phone call 2x a month</li>
              <li>Hannon’s Pro room</li>
            </ul>
            <div className="ov-subscription-rating">
              <span className="ov-subscription-stars">★★★★☆</span>
              <span className="ov-subscription-reviews ov-13">(882)</span>
            </div>
          </div>
          <button className="ov-subscription-button bold ov-16">Buy</button>
        </div>
      </div>

      </div>

        <div className = "ov-subscription-col2">
          <div className = "ov-subscription-col2-row1"> 
            <div className = "ov-subscription-c2-r1-1">
              <h2 className="ov-16 bold" style = {{margin: '0 0 1rem 0'}}>Awards:</h2>
              <div className = "ov-subscription-awards">
                <img src = {award1} alt = "award1" className = "ov-award"/>
                <img src = {award2} alt = "award2" className = "ov-award"/>
                <img src = {award3} alt = "award3" className = "ov-award"/>
                <img src = {award4} alt = "award4" className = "ov-award"/>
              </div>
            </div>
            <div className = "ov-subscription-c2-r1-2 ov-16">
              <h2 className="ov-16 bold" style = {{margin: '0 0 0.5rem 0'}} >Rankings:</h2>
              <p><span className = "bold ov-green ov-16">#1</span> on the <span className = "bold">daily leaderboard</span></p>
              <p><span className = "bold ov-green ov-16">#3</span> in <span className = "bold">USA</span></p>
              <p><span className = "bold ov-green ov-16">#40</span> in <span className = "bold">best communication</span></p>
              <p><span className = "bold ov-green ov-16">#10</span> in <span className = "bold">tech investors</span></p>
              <p><span className = "bold ov-green ov-16">#35</span> <span className = "bold">most subscribers</span></p>

            </div>
          </div>
          <div className="ov-subscription-col2-row2">
        <h2 className="ov-16 bold" style={{ margin: 0 }}>Portfolio allocation</h2>

        <div className="ov-subscription-portfolio-row" style={{ display: 'flex', alignItems: 'center' }}>
          <img src={portfolioChart} className="ov-subscription-chart" alt='portfolioChart' />

          <div className="ov-subscription-allocate ov-13">
        <h3 className="ov-13 bold">Stocks (50%)</h3>

        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p1} className="ov-color" alt="p1" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">AAPL</span>
            <span>31.35%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color2" type="image/svg+xml" data={p2} className="ov-color" alt="p2" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">TSLA</span>
            <span>22.43%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p3} className="ov-color" alt="p3" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">GOOG</span>
            <span>18.56%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p4} className="ov-color" alt="p4" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">MSFT</span>
            <span>17.79%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p5} className="ov-color" alt="p5" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">NVDA</span>
            <span>10.87%</span>
          </div>
        </div>
      </div>

      <div className="ov-subscription-allocate ov-13">
        <h3 className="ov-13 bold">Crypto (30%)</h3>

        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p6} className="ov-color" alt="p6" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">BTC</span>
            <span>21.32%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p7} className="ov-color" alt="p7" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">SOL</span>
            <span>20.18%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p8} className="ov-color" alt="p8" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">ETH</span>
            <span>20.26%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p9} className="ov-color" alt="p9" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">DOGE</span>
            <span>19.45%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p10} className="ov-color" alt="p10" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">PEPE</span>
            <span>12.79%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p11} className="ov-color" alt="p11" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">SHIB</span>
            <span>6.00%</span>
          </div>
        </div>
      </div>

      <div className="ov-subscription-allocate ov-13">
        <h3  className="ov-13 bold">NFTs (10%)</h3>

        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p12} className="ov-color" alt="p12" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">Bored Ape</span>
            <span>52.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p13} className="ov-color" alt="p13" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">Pudgy Penguins</span>
            <span>13.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p14} className="ov-color" alt="p14" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">Azuki</span>
            <span>20.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p15} className="ov-color" alt="p15" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">Kets</span>
            <span>20.55%</span>
          </div>
        </div>
      </div>

      <div className="ov-subscription-allocate ov-13">
        <h3 className="ov-13 bold">Currencies (10%)</h3>

        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p16} className="ov-color" alt="p16" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">GBP/USD</span>
            <span>20.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p17} className="ov-color" alt="p17" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">EUR/USD</span>
            <span>20.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p18} className="ov-color" alt="p18" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">XAU/USD</span>
            <span>20.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p19} className="ov-color" alt="p19" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">GBP/JPY</span>
            <span>20.55%</span>
          </div>
        </div>
        <div className="ov-subscription-portfolio-item" style={{ display: 'flex' }}>
          <object aria-label="color1" type="image/svg+xml" data={p20} className="ov-color" alt="p20" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="bold">GBP/JPY</span>
            <span>20.55%</span>
          </div>
        </div>
      </div>

        </div>
      </div>

      {/* <div className="ov-overview-cloud-container">
                <img src={cloud} alt="Cloud" className="ov-overview-cloud" />
              </div> */}

        </div>

      </div>
</div>
<div className = "ov-final-container">
    <h2 className = "bold">
      Invest with the best
    </h2>
    <span className = "bold ov-16" 
     onClick={scrollToTop}
    style = {{fontSize: '1.2rem', cursor: 'pointer'}}>Back to top</span>
    <object aria-label="rocket" type="image/svg+xml" data={rocket} className="ov-final-image" />

  </div>




    </div>
  );
}
