import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import bookmark from '../../assets/bookmark.svg';
import greenBookmark from '../../assets/greenBookmark.svg';

export default function Bookmark({isBookmarked, setIsBookmarked, onBookmarkClick}) {
    const {isAuthenticated} = useContext(AuthContext);

    const handleBookmarkClick = () => {
        if (!isAuthenticated) { 
          alert('Please sign in to bookmark posts'); 
          return;
        }
        onBookmarkClick();
        setIsBookmarked(!isBookmarked);
      };

  return (
    <div className="ActionItem" onClick={handleBookmarkClick}>
    <object aria-label="bookmark" type="image/svg+xml" data={isBookmarked ? greenBookmark : bookmark} alt="Bookmark" className={`BookmarkIcon ${isBookmarked ? 'Bookmarked' : ''}`} />
  </div>

  )
}
