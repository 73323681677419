import React, { useState, useContext, useEffect } from 'react';
import '../styles/Settings.css';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import { useNavigate } from 'react-router-dom';

export default function Settings() {
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPublicState, setIsPublicState] = useState(false);
  const navigate = useNavigate();


  const { phoneNum, email, isPublic, userToken, userId, setPhoneNum, setIsPublic, setEmail } = useContext(AuthContext);

  useEffect(() => {
    setPhoneNumber(phoneNum);
    setEmailAddress(email);
    setIsPublicState(isPublic);
  }, [phoneNum, email, isPublic]);

  const handleEditClick = () => {
    if (isEditing) {
      saveChanges();
    }
    setIsEditing(!isEditing);
  };

  const saveChanges = async () => {
    try {
      const response = await fetch(`${config.backendUrl}/api/auth/update-profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          userId,
          phoneNum: phoneNumber,
          email: emailAddress,
          isPublic: isPublicState,
        }),
      });
      const data = await response.json();
      if (response.status === 200) {
        setPhoneNum(phoneNumber);
        setEmail(emailAddress);
        setIsPublic(isPublicState);
        localStorage.setItem('phoneNum', phoneNumber);
        localStorage.setItem('email', emailAddress);
        localStorage.setItem('isPublic', isPublicState);
        alert('Profile updated successfully');
      } else {
        alert(data.message || 'Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  return (
    <div className="se-page-container">
      <div className="se-settings-container">
        <h1 className="se-settings-title bold">Settings</h1>
        <button className="se-edit-button bold" onClick={handleEditClick}>
          {isEditing ? 'Save' : 'Edit'}
        </button>

        <div className="se-section">
          <h2 className="se-section-title bold">Account info</h2>
          <div className="se-info-grid">
            <div className="se-info-item">
              <span className="se-info-label">Phone #</span>
              {isEditing ? (
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="se-info-value editable"
                />
              ) : (
                <span className="se-info-value">{phoneNum}</span>
              )}
            </div>
            <div className="se-info-item">
              <span className="se-info-label">Email</span>
              {isEditing ? (
                <input
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  className="se-info-value editable"
                />
              ) : (
                <span className="se-info-value">{email}</span>
              )}
            </div>
          </div>
        </div>

        <div className="se-section">
          <h2 className="se-section-title bold">Security and privacy</h2>
          <div className="se-info-grid">
            <div className="se-info-item">
              <span className="se-info-label">Account visibility</span>
              <div className="se-checkbox-container">
                <input
                  style={{ margin: 0 }}
                  type="checkbox"
                  id="se-public-checkbox"
                  checked={isPublicState}
                  onChange={() => setIsPublicState(!isPublicState)}
                  disabled={true} //always disabled for now
                />
                <label htmlFor="se-public-checkbox">Public</label>
              </div>
            </div>
          </div>
        </div>

        <div className="se-section">
          <h2 className="se-section-title bold">Billing information</h2>
          <button className="se-link-button bold">Link credit / debit card</button>
        </div>

        <div className="se-section">
          <h2 className="se-section-title bold">Manage subscription</h2>
          <p className="se-subscription-details">Vestr+ Gold: $35 / month</p>
          <p className="se-subscription-details">Next payment: July 4, 1776</p>
          <button className="se-link-button bold" style={{ marginTop: '1rem' }}>Change subscription</button>
        </div>

        <div className="se-policies">
              <span 
              onClick={() => navigate('/privacy', 
                {
                state: { fromSettings: true } 
                }

              )}
              className="se-terms-link bold">
                Privacy Policy
                </span>
              <span 
              onClick={() => navigate('/terms', {
                state: { fromSettings: true } 
                }
              )}
              className="se-terms-link bold">
                Terms of Service
                </span>
          </div>
      </div>
    
    </div>
  );
}