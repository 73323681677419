import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { handleDownvoteClick, submitDownvoteCount } from '../../helper/PostHelper';
import downvote from '../../assets/downvote.svg';
import redDownvote from '../../assets/redDownvote.svg';
import config from '../../config';
import TextField from '@mui/material/TextField';

export default function Downvote({ isAdmin, isDownvotedState, setIsDownvotedState, setIsUpvotedState, setDownvoteCount, setUpvoteCount, postId, downvoteCount }) {
  const { isAuthenticated, userToken } = useContext(AuthContext);
  const [downvoteAnchorEl, setDownvoteAnchorEl] = useState(null);
  const [downvoteCountInput, setDownvoteCountInput] = useState(1);

  const closePopover = () => {
    setDownvoteAnchorEl(null);
  };

  const handleDownvote = (event) => {
    if (isAdmin && !isDownvotedState) {
      setDownvoteAnchorEl(event.currentTarget);
    } else {
      handleDownvoteClick({
        isAuthenticated,
        currentUser: { isAdmin },
        isDownvoted: isDownvotedState,
        setIsDownvoted: setIsDownvotedState,
        setIsUpvoted: setIsUpvotedState,
        setDownvoteCount,
        setUpvoteCount,
        config,
        postId,
        userToken,
      });
    }
  };

  return (
    <>
      <div className="ActionItem" onClick={handleDownvote}>
        <object
          aria-label="downvotes"
          type="image/svg+xml"
          data={isDownvotedState ? redDownvote : downvote}
          alt="Downvote"
          className="ActionIcon"
        />
        {downvoteCount}
      </div>

      {/* Downvote Popover */}
      {isAdmin && (
        <Popover
          style = {{fontFamily:'SF-Pro-Medium'}}
          open={Boolean(downvoteAnchorEl)}
          anchorEl={downvoteAnchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              label="Add downvotes"
              type="number"
              value={downvoteCountInput}
              onChange={(e) => setDownvoteCountInput(e.target.value)}
              slotProps={{
                input: {
                  inputProps: { min: 1 }, // Set the min property for the input element
                },
              }}
            />
            <Button
              onClick={() =>
                submitDownvoteCount({
                  downvoteCountInput,
                  setDownvoteCount,
                  setUpvoteCount,
                  setIsDownvoted: setIsDownvotedState,
                  setIsUpvoted: setIsUpvotedState,
                  closeDownvoteModal: closePopover,
                  config,
                  postId,
                  userToken,
                })
              }
            >
              Submit
            </Button>
            <Button onClick={closePopover}>Cancel</Button>
          </div>
        </Popover>
      )}
    </>
  );
}
