import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SignUp.css';
import rocket from '../assets/rocketspace.svg';

//Currently not used
const SignUp = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //await axios.post('http://localhost:3001/signup', { email });
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
      alert('Error signing up');
    }
  };


  return (
    <div className="signup-container">
      {!isSubmitted ? (
        <div className="signup-box">
          <h1>Join the waitlist!</h1>
          <p style = {{color: '#707070'}}>Have an account? <button onClick={() => navigate('/signin')} className="link-button">Launch beta</button></p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="signup-button">Join waitlist</button>
          </form>
          <div className="back-link-container">
            <button onClick={() => navigate('/')} className="back-link">← Back to landing page</button>
          </div>
        </div>
      ) : (
        <div className="thank-you-box">
          <p>Thank you! You will receive a registration link and instructions when we add more users.</p>
          <div className="back-link-container">
            <button onClick={() => navigate('/')} className="back-link">← Back to landing page</button>
          </div>
        </div>
      )}
      <img src={rocket} alt="Beta launch" className="signup-image" />
    </div>
  );
};

export default SignUp;
