import React from 'react';

//Styles for the + selects
export const customStylesPlus = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#f2f2f2',
      border: 'none',
      borderRadius: '2rem',
      padding: '0 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', 
      minHeight: '3rem',
      maxHeight: '3rem',    
      overflow:'auto',
      cursor: 'pointer',
      fontSize: '1.25rem',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#e0e0e0' : isFocused ? '#f2f2f2' : 'white',
      color: '#000',
      padding: '0.5rem 2rem',
      cursor: 'pointer',
      fontSize: '1.25rem',
      textAlign: 'left',
      zIndex: 999999999,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#f2f2f2',
      borderRadius: '1rem',
      padding: 0,
      margin: 0,
      justifyContent: 'center', 
      fontSize: '1.25rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', 
      zIndex: 999999999,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#000',
      fontSize: '1.25rem',
      padding: '0 0.5rem',
      backgroundColor:'#fff',
      borderRadius: '0.25rem 0 0 0.25rem',
      cursor: 'pointer',
      zIndex: 999999999,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#000',
      backgroundColor:'#fff',
      marginRight: '0.5rem',
      borderRadius: '0 0.25rem 0.25rem 0',
      height: '1.5rem',
      cursor: 'pointer',
      
      ':hover': {
        backgroundColor: '#e0e0e0',
        color: 'black',
      },
    }),
    dropdownIndicator: (styles) => ({
      display: 'none' , 
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '0.5rem',
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
      zIndex: 999999999,

    }),
    singleValue: (styles) => ({
      ...styles,
      padding: '0 0.5rem',
      display: 'flex',
      justifyContent: 'center', // Centers the text within the single value
      width: '100%', // Ensures the single value takes up the full width of the control
      cursor: 'pointer',
      fontSize: '1.25rem',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#000',
      fontSize: '1.25rem',
    }),
    
    
  };


//Styles for other selects
export const customStyles={
    control: (provided) => ({
      ...provided,
      backgroundColor: '#f2f2f2',
      height: '3rem',
      minHeight: '3rem',
      padding: '0 2rem',
      borderRadius: '2rem', 
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      fontSize: '1.25rem',
      cursor: 'pointer',

    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#333', 
      padding: '0', 
      cursor: 'pointer',
      fontSize: '1.25rem',
      
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.5rem', 
      fontSize: '1.25rem',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0.5rem', 
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#e0e0e0' : state.isFocused ? '#f2f2f2' : 'white', 
      color: '#000',
      padding: '0.5rem 2rem', 
      cursor: 'pointer', 
      fontSize: '1.25rem',
      textAlign: 'left',
    }),
  };
  const ArrowSvg  = () => {
    return (
      <svg height="2rem" width="2rem" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
    );
  }
  

  export const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} 
    style = {{
      backgroundColor: '#f2f2f2',
      border: 'none',
      borderRadius: '2rem',
      padding: '0 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', 
      minHeight: '3rem',
      maxHeight: '3rem',    
      overflow:'auto',
      cursor: 'pointer',
      fontSize: '1.25rem',

    }}>
      {value || "Select Date"}
      <ArrowSvg />
    </div>
  ));
  
  
 