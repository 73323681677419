import React, { useContext, useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Header.css';
import vestrLogo1 from '../assets/logo1.svg';
import vestrLogo2 from '../assets/logo2.svg';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import config from '../config';
import { debounce } from 'lodash';
const Header = () => {
  const { isAuthenticated, username, signOut, name, profilePicture, userToken } = useContext(AuthContext);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [indicesData, setIndicesData] = useState([]); 
  const [canScrollLeft, setCanScrollLeft] = useState(false); 
  const [canScrollRight, setCanScrollRight] = useState(true);

  const navigate = useNavigate();





  useEffect(() => {
    const fetchIndicesData = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/polygon`);
        setIndicesData(response.data);
        setCanScrollRight(response.data.length > 6);
      } catch (error) {
        console.error('Error fetching indices data:', error);
      }
    };

    fetchIndicesData();
  }, []);

  const handleChevronLeft = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 6, 0);
      setCanScrollRight(true);
      setCanScrollLeft(newIndex > 0);
      return newIndex;
    });
  };
  
  const handleChevronRight = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = Math.max(indicesData.length - 6, 0);
      const newIndex = Math.min(prevIndex + 6, maxIndex);
      setCanScrollRight(newIndex < maxIndex);
      setCanScrollLeft(newIndex > 0);
      return newIndex;
    });
  };

  const toggleProfileModal = () => {
    setIsProfileModalVisible(!isProfileModalVisible);
  };

  const handleSignOut = () => {
    signOut();
    toggleProfileModal();
    navigate('/');
  };

  const handleViewProfile = () => {
    navigate(`/profile/${username}`);
  };

  const fetchSearchResults = async (query) => {
    if (!isAuthenticated) {
      alert('Please sign in to search for users.');
      return;
    }
  
    if (query.length > 0) {
      try {
        const response = await axios.get(`${config.backendUrl}/api/auth/search-users`, {
          params: { query },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setSearchResults(response.data);
        setIsDropdownVisible(true);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      setIsDropdownVisible(false);
    }
  };
  
  //eslint-disable-next-line
  const debouncedFetchSearchResults = useCallback(
    debounce((query) => {
      if (isAuthenticated) {
        fetchSearchResults(query, userToken);
      }
    }, 300),
    [isAuthenticated, userToken]
  );
  
  const handleSearch = (e) => {
    if (!isAuthenticated) {
      alert('Please sign in to search for users.');
      return;
    }
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchSearchResults(query);
  };

  const handleSelectUser = (selectedUsername) => {
    navigate(`/profile/${selectedUsername}`);
    setSearchQuery('');
    setIsDropdownVisible(false);
  };

   
  

  return (
    <div className="HeaderContainer">
      <div className="Header">
        <div className="KeyIndicators">
          <button className="dropdown-button bold">
            Key indicators :
          </button>
     
          {indicesData.slice(currentIndex, currentIndex + 6).map(index => (
          <div key={index.name} className="indicator">
          <span className="bold">{index.name}</span> ${index.close.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
          <span className={index.isPositive ? "positive" : "negative"}>
            {index.isPositive ? '↑' : '↓'}{index.percentChange}%
          </span>
        </div>
          ))}
          <div className="chevron-icons">
            <button
              className="icon-button"
              style={{ backgroundColor: 'transparent', width: '2rem' }}
              onClick={handleChevronLeft}
              disabled={!canScrollLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="sm" color={canScrollLeft ? '#000' : '#aaa'} />
            </button>
            <button
              className="icon-button"
              style={{ backgroundColor: 'transparent', width: '2rem' }}
              onClick={handleChevronRight}
              disabled={!canScrollRight}
            >
              <FontAwesomeIcon icon={faChevronRight} size="sm" color={canScrollRight ? '#000' : '#aaa'} />
            </button>
          </div>
        </div>

        <div className="Icons">
          <button className="vestr-button bold" onClick={() => navigate('vestrplus')}>Vestr+</button>
          {isAuthenticated && <button className="icon-button" onClick={() => navigate('/settings')}>
            <FontAwesomeIcon icon={faCog} size="lg" />
          </button>}
          <div className="profile" onClick={handleViewProfile}>
            {isAuthenticated ? (
              <>
                <div className="profile-nav-container">
                  <img className="profile-pic" src={`${profilePicture}`} alt="Profile pic" />
                  <span style={{ fontSize: '1.1rem' }} className="bold">{name ? name : "User"}</span>
                </div>
              </>
            ) : (
              <NavLink to="/signin" style={{ textDecoration: 'none', color: 'black', fontSize: '1.1rem' }} className="bold">Sign In</NavLink>
            )}
          </div>
          {isProfileModalVisible && isAuthenticated && (
            <div className="profile-modal">
              <button className="profile-modal-item" onClick={handleSignOut}>
                Sign Out
              </button>
              <button style={{ borderTop: '1px solid #ccc' }} className="profile-modal-item" onClick={handleViewProfile}>
                View Profile
              </button>
            </div>
          )}
        </div>
     
      </div>
      <hr className='hr-header' />
      <div className="SubHeader">
        <div className="nav-items">
          <NavLink to="/" className="nav-button-logo">
            <object aria-label="vestr logo 1" className="vestr-logo" type="image/svg+xml" data={vestrLogo1}></object>
          </NavLink>
          <NavLink to="/" className={({ isActive }) => `nav-button bold ${isActive ? 'active' : ''}`}>
            Overview
          </NavLink>
          <NavLink to="/feed" className={({ isActive }) => `nav-button bold ${isActive ? 'active' : ''}`}>
            Feed
          </NavLink>
          {/* <NavLink to="/news" className={({ isActive }) => `nav-button bold ${isActive ? 'active' : ''}`}>
            Newspaper
          </NavLink>
             <NavLink to="/markets" className={({ isActive }) => `nav-button bold ${isActive ? 'active' : ''}`}>
           Markets
          </NavLink> */}
          <NavLink to="/mrvestr" className={({ isActive }) => `nav-button bold ${isActive ? 'active' : ''}`}>
            Mr. Vestr
          </NavLink>
       

        
          
        </div>
        <div className="subheader-ads">
          <div className="h-search-container">
            <input
              type="text"
              className="h-search-input"
              placeholder="Search Vestr"
              value={searchQuery}
              onChange={handleSearch}
            />
            {isDropdownVisible && searchResults.length > 0 && (
              <div className="h-search-dropdown">
                {searchResults.map(user => (
                  <div
                    key={user._id}
                    className="h-search-result-item"
                    onClick={() => handleSelectUser(user.username)}
                  >
                    <img className="h-search-result-pic" src={`${user.profilePicture}`} alt={user.username} />
                    <div>
                      <span className="bold">{user.name} <span> @{user.username}</span> </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="ad-space">Give us feedback on<br />Twitter @DownloadVestr</div>
          <object aria-label="vestr logo 2" className="vestr-logo-2" type="image/svg+xml" data={vestrLogo2}></object>
        </div>
      </div>
      <hr className='hr-header' />
    </div>
  );
};

export default Header;
