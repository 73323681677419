import React, { useContext, useState, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../styles/SignIn.css';
import rocket from '../assets/rocketspace.svg';
import config from '../config';

//OTP being used as pin
const SignIn = () => {
  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [view, setView] = useState('createAccount');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [otpCode, setOtpCode] = useState(Array(6).fill(''));
  const [unverifiedUser, setUnverifiedUser] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [financialDisclaimerAccepted, setFinancialDisclaimerAccepted] = useState(false);


  const otpCodeRefs = useRef([]);

  const handleOtpCodeChange = (e, index) => {
    const value = e.target.value;
    const newOtpCode = [...otpCode];
    if (/^[0-9]$/.test(value) || value === '') {
      newOtpCode[index] = value;
      setOtpCode(newOtpCode);
      if (value && index < otpCodeRefs.current.length - 1) {
        otpCodeRefs.current[index + 1].focus();
      }
    }
    if (e.key === 'Backspace' && !value && index > 0) {
      newOtpCode[index - 1] = '';
      setOtpCode(newOtpCode);
      otpCodeRefs.current[index - 1].focus();
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/signin`, {
        phone,
      });
      if (response.status === 200) {
        setUnverifiedUser({ phone });
        setView('otp');
      }
    } catch (error) {
      console.error(error);
      alert('Error signing in');
    }
  };

  const handleCreateAccountSubmit = async (e) => {

    e.preventDefault();

 
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/signup`, {
        email,
        username,
        phone,
        name
      });
      if (response.status === 200) {
        setUnverifiedUser({ phone });
        setView('create-otp');

      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/verify-otp`, {
        phone: unverifiedUser ? unverifiedUser.phone : phone,
        otp: otpCode.join('')
      });
      if (response.status === 200) {
        signIn(response.data.user);
        navigate('/');
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const handleOtpCreate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.backendUrl}/api/auth/create-otp`, {
        otp: otpCode.join(''),
        email,
        username,
        phone,
        name
      });
      if (response.status === 200) {
        setOtpCode(Array(6).fill(''));  
        setView('otp');
      }
    } catch (error) {
      console.error(error);
      alert('Error creating OTP');
    }
  }
  

  return (
    <div className="signin-container">
      <div className="signin-box">
        {view === 'login' && (
          <>
            <h1>Log in</h1>
            <form onSubmit={handleLoginSubmit} className="login-form">
              <input
                type="text"
                placeholder="Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <button type="submit" className="signin-button bold">Sign in</button>
              <button onClick={() => navigate('/')} className="back-link">← Back to landing page</button>
              <p style={{ marginBottom: '0rem', color: '#707070', fontSize: '1.2rem' }}>
                Don’t have an account? <button onClick={() => setView('createAccount')} className="link-button">Sign up</button>
              </p>
            </form>
          </>
        )}
        {view === 'createAccount' && (
          <>
            <h1 style={{ marginBottom: '2rem' }}>Create your account!</h1>
            <form onSubmit={handleCreateAccountSubmit} className="create-account-form">
  <input
    type="text"
    placeholder="Name"
    value={name}
    onChange={(e) => setName(e.target.value)}
    required
  />
  <input
    type="text"
    placeholder="Username"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    required
  />
  <input
    type="email"
    placeholder="Email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
  />
  <input
    type="tel"
    placeholder="Phone number"
    value={phone}
    onChange={(e) => setPhone(e.target.value)}
    required
    title='Phone number must be 10 digits: Ex: 1234567890'
  />

  <div className="terms-container-signup">
    <label className="terms-label">
      <input
        type="checkbox"
        checked={termsAccepted}
        onChange={(e) => setTermsAccepted(e.target.checked)}
        required
        style= {{margin:0, width: 'auto'}}
      />
      <span style = {{width: '25rem'}}>
      I have read and agreed to the <a target="_blank" href= "/privacy" className="terms-link bold">privacy policy</a> and 
      <a target="_blank" href="/terms" className="terms-link bold"> terms of service</a>. I am over 13 years old.
      </span>
    </label>
  </div>

  <div className="disclaimer-container-signup">
    <label className="disclaimer-label">
      <input
        type="checkbox"
        checked={financialDisclaimerAccepted}
        onChange={(e) => setFinancialDisclaimerAccepted(e.target.checked)}
        required
        style= {{margin:0, width: 'auto'}}
      />
      <span style = {{width: '25rem'}}>
      I understand that content on Vestr is not financial advice. Vestr is a tool to inform me, not make decisions for me.
      </span>
    </label>
  </div>

  <button type="submit" className="signin-button bold" >
    Create account
  </button>
  <button onClick={() => navigate('/')} className="back-link">← Back to landing page</button>
</form>

            <p style={{ marginBottom: '0rem', color: '#707070', fontSize: '1.2rem' }}>
              Already have an account? <button onClick={() => setView('login')} className="link-button">Sign in</button>
            </p>
          </>
        )}
        {view === 'otp' && (
          <>
            <h1>Enter PIN</h1>
            <form onSubmit={handleOtpSubmit} className="otp-form">
              <div className="otp-inputs">
                {otpCode.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpCodeChange(e, index)}
                    onKeyDown={(e) => handleOtpCodeChange(e, index)}
                    ref={(el) => (otpCodeRefs.current[index] = el)}
                  />
                ))}
              </div>
              <button type="submit" className="signin-button">Launch beta</button>
              <button onClick={() => setView('createAccount')} className="back-link">← Back to account info</button>
            </form>
          </>
        )}

        {view === 'create-otp' && (
                  <>
                    <h1 className="bold">Create PIN</h1>
                    <form onSubmit={handleOtpCreate} className="otp-form">
                      <div className="otp-inputs bold">
                        {otpCode.map((value, index) => (
                          <input
                            className="otp-input bold"
                            key={index}
                            type="text"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpCodeChange(e, index)}
                            onKeyDown={(e) => handleOtpCodeChange(e, index)}
                            ref={(el) => (otpCodeRefs.current[index] = el)}
                          />
                        ))}
                      </div>
                      <button type="submit" className="signin-button">Launch beta</button>
                      <button onClick={() => setView('createAccount')} className="back-link">← Back to account info</button>
                    </form>
                  </>
                )}



        
      </div>
      <img src={rocket} alt="Beta launch" className="signin-image" />
    </div>
  );
};

export default SignIn;